import { List, DateField, FunctionField, NumberField, SelectInput } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import StateTextField from '../Commons/StateTextField'
import ListActions from '../Commons/ListActions';
import { useMediaQuery } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import { StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import historyTracker from '../Commons/historyTracker';
import { useLocation } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useState, useEffect } from "react";

const ClearingList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const [historyInfo, setHistoryInfo] = useState(historyTracker.getHistory());
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        historyTracker.cleanHistory();
        historyTracker.addPath(location.pathname, 'Liquidaciones', 0);
        setHistoryInfo(historyTracker.getHistory());
      }
      catch {
      }
    })();
  }, []);

  const PostFilter = [
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>Estado</span>} source="state"
      choices={[{ id: 0, name: 'Pendiente' }, { id: 1, name: 'Liquidado' }]} alwaysOn />
  ]

  return (
    <div class="container">
      <BreadCrumbCustom history={historyInfo} />
      {isSmall ? (
        <List empty={false} {...props} bulkActionButtons={false} actions={<ListActions download={true} create={false} />} pagination={<PostPagination />} >
          <Datagrid {...props} rowClick="show" optimized>
            <FunctionField label="Estado" cellClassName={classes.fields} render={(record) => {
              switch (record.liquidado) {
                case false:
                  return <StateTextField state="Pendiente" color="#2A3353" width="8em" height="2em" small={true} />;
                case true:
                  return <StateTextField state="Liquidado" color="#01BF8F" width="8em" height="2em" small={true} />;
                default:
                  break;
              }
              return `${record.state}`
            }} />
            <NumberField source="totalIngresos" style={{ color: 'black', fontFamily: 'Gothic' }} label="Total" options={{ style: 'currency', currency: 'GTQ' }} sortable={false} cellClassName={classes.fields} />
          </Datagrid>
        </List>
      ) : (
        <List empty={false} {...props} bulkActionButtons={false} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={{}} />} actions={<ListActions download={true} create={false} />} pagination={<PostPagination />} >
          <Datagrid rowClick="show" style={{ textAlign: 'center' }}>
            <FunctionField label="Estado" cellClassName={classes.fields} render={(record) => {
              switch (record.liquidado) {
                case false:
                  return <StateTextField state="Pendiente" color="#2A3353" width="8em" height="2em" paddingTop="3%" />;
                case true:
                  return <StateTextField state="Liquidado" color="#01BF8F" width="8em" height="2em" paddingTop="3%" />;
                default:
                  break;
              }
              return `${record.state}`
            }} />

            <NumberField source="totalIngresos" cellClassName={classes.fields} style={{ color: 'black', fontFamily: 'Gothic' }} options={{ style: 'currency', currency: 'GTQ' }} label="Total" sortable={false} />
            <NumberField source="cantidadTransacciones" cellClassName={classes.fields} style={{ color: 'black', fontFamily: 'Gothic' }} label="Transacciones" sortable={false} />
            <DateField source="created" style={{ color: 'black', fontFamily: 'Gothic' }} cellClassName={classes.fields} label="Creado" showTime={true} sortable={false} />
          </Datagrid>
        </List>
      )}
    </div >
  )
};

export default ClearingList;