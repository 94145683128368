import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useListContext } from 'react-admin';
import { Style } from '../Commons/Styles.jsx';
import { useMediaQuery } from '@material-ui/core';

const PostPagination = (props) => {
    const { noPerPage, menu, small } = props;
    const { page, perPage, total, setPage, setPerPage } = useListContext();
    let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const nbPages = Math.ceil(total / perPage) || 1;
    let col = page > 1 ? 2 : 0;
    col += page !== nbPages ? 2 : 0;
    col += total > 10 && !noPerPage ? 1 : 0;
    col += menu ? 1 : 0;
    col = (isSmall ? 11 : 12) - col;
    let prev = small || isSmall ? '' : 'Anterior';
    let next = small || isSmall ? '' : 'Siguiente';
    return (
        <Toolbar>
            <div class="row" style={Style.AllWidth}>
                {page > 1 &&
                    <div class="col-2">
                        <Button style={Style.PostPaginatioinButton} key="prev" onClick={() => setPage(page - 1)}>
                            <ChevronLeft />
                            {prev}
                        </Button>
                    </div>
                }
                {page !== nbPages &&
                    <div class="col-2">
                        <Button style={Style.PostPaginatioinButton} key="next" onClick={() => setPage(page + 1)}>
                            {next}
                            <ChevronRight />
                        </Button>
                    </div>
                }
                <div class={"col-" + col}></div>
                {(total > 10 && !noPerPage) &&
                    <div class="col-1">
                        <select onChange={(e) => { setPerPage(e.target.value) }} style={Style.SelectCustom}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                }
                {menu &&
                    <div class="col-1">
                        {menu}
                    </div>
                }
            </div>
        </Toolbar>
    );
}

export default PostPagination;