import trans from '../Commons/Icons/Tarjeta.svg';
import wire from '../Commons/Icons/Transaccion.svg';
import cash from '../Commons/Icons/cash-svgrepo-com.svg';
import installment from '../Commons/Icons/calendar-svgrepo-com.svg';

const TypeField = ({ type, width, height }) => {
    var maxw = width ? width : '3em';
    var maxh = height ? height : '3em';
    var src = type === 0 ? trans : type === 1 ? wire : type === 2 ? installment : cash ;
    return (<div >
        <img src={src} alt="logo" style={{ maxWidth: maxw, maxHeight: maxh }} />
    </div>)
}

export default TypeField;