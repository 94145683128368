import { useRedirect, useDataProvider } from 'react-admin';
import InputFieldCustom from '../Commons/InputFieldCustom'
import SelectCustom from '../Commons/SelectCustom';
import { useMediaQuery } from '@material-ui/core';
import { useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { useNotify } from 'react-admin';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileButton from "../Commons/FileButton";
import BottomToolBar from '../Commons/BottomToolBar';
import { StyleTheme } from '../Commons/Styles.jsx'
import { createStyles, makeStyles } from '@material-ui/core';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum'

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProductCreate = (props) => {
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState(false);
    const [description, setDescription] = useState('');
    const [errorDescription, setErrorDescription] = useState(false);
    const [additional, setAdditional] = useState('');
    const [sku, setSKU] = useState('');
    const [errorSKU, setErrorSKU] = useState(false);
    const [limit, setLimit] = useState('');
    const [errorLimit, setErrorLimit] = useState(false);
    const [price, setPrice] = useState('');
    const [errorPrice, setErrorPrice] = useState(false);
    const [type, setType] = useState('G');
    const types = [{ 'key': 'G', 'value': 'Bien' }, { 'key': 'S', 'value': 'Servicio' }];
    const [unit, setUnit] = useState('U');
    const units = [{ 'key': 'U', 'value': 'Unidad' }, { 'key': 'lb', 'value': 'Libra' }, { 'key': 'S', 'value': 'Servicio' }]
    const [openerror, setOpenerror] = useState(false);
    const [image, setImage] = useState(undefined);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const redirect = useRedirect();

    let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const notify = useNotify();

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const CreateProduct = async () => {
        let control = 0;
        if (!Validate(name, { product: true, required: true })) {
            setErrorName(true);
            control++;
        }

        if (!Validate(description, { required: true })) {
            setErrorDescription(true);
            control++;
        }

        if (!Validate(sku, { required: true })) {
            setErrorSKU(true);
            control++;
        }

        if (!Validate(limit, { number: true, required: true })) {
            setErrorLimit(true);
            control++;
        }

        if (!Validate(price, { money: true, required: true })) {
            setErrorPrice(true);
            control++;
        }

        if (image === undefined) {
            control++
        }

        if (control > 0) {
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var data = {
            name,
            description,
            price,
            limit,
            image,
            additionalInfo: additional,
            sku,
            type,
            unit
        }

        dataProvider.create('products', { data: data })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                redirect('/products/' + response.data.id);
                notify('Producto creado exitosamente');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClickError()
            })
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit">
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div style={{ opacity: opacity }}>
                        <div class="row">
                            <div class="col-4">
                                <div row>
                                    <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '0.1em' }}>Datos del Producto</span>
                                </div>
                                <div row>
                                    <span style={{ fontFamily: 'Gothic', color: '#AAAAAA', fontSize: '0.9em', marginLeft: '0.1em' }}>Este apartado contiene los datos que el cliente verá al usar el link de este producto</span>
                                </div>
                            </div>
                            <InputFieldCustom value={name} setValue={setName} col="col-8" width="95%" placeholder="Ingresa nombre del producto (max 50 caracteres)" label="Nombre del Producto *" required error={errorName} setError={setErrorName}/>
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom value={description} setValue={setDescription} col="col-8" width="95%" placeholder="Ingresa descripción del producto" label="Descripción del Producto *" required component="textarea" error={errorDescription} setError={setErrorDescription}/>
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom value={additional} setValue={setAdditional} col="col-8" width="95%" placeholder="Ingresa información adicional del Producto" label="Información Adicional" component="textarea" />
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom value={sku} setValue={setSKU} col="col-8" width="95%" placeholder="Ingresa SKU del producto" label="SKU del Producto *" required error={errorSKU} setError={setErrorSKU}/>
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom value={limit} setValue={setLimit} col="col-8" width="95%" placeholder="Ingresa límite por transacción" label="Límite *" required number error={errorLimit} setError={setErrorLimit} />
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom value={price} setValue={setPrice} col="col-8" width="95%" placeholder="Ingresa el precio del producto" label="Precio (Q) *" required money error={errorPrice} setError={setErrorPrice} />
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom setValue={setType} options={types} select={type} component="select" col="col-8" label="Tipo" width="95%" />
                        </div>
                        <div class="row">
                            <div class="col-4"></div>
                            <InputFieldCustom setValue={setUnit} width="95%" options={units} select={unit} component="select" col="col-8" label="Unidad" />
                        </div>
                        <div class="row" style={{ marginTop: '4em' }}>
                            <div class="col-4" style={{ paddingTop: '0.5em' }}>
                                <div row>
                                    <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '0.1em' }}>Imagen del Producto</span>
                                </div>
                                <div row>
                                    <span style={{ fontFamily: 'Gothic', color: '#AAAAAA', fontSize: '0.9em', marginLeft: '0.1em' }}>Este apartado ingresarás la imagen que verán los clientes de tu producto, recomendamos usar una imagen con fondo blanco y de una proporción de 500x500px </span>
                                </div>
                            </div>
                            <div class="col-8" style={{ marginLeft: "-1em" }}>
                                <FileButton title="" setValue={setImage} id="productimage" image={null} heightimg={'100%'} widthimg={"100%"} />
                            </div>
                        </div>
                        <BottomToolBar buttons={[{ auth: true, action: CreateProduct, name: 'Crear', disabled: disable }]} />
                        <div class="row" style={{ textAlign: 'center', marginBottom: '1em', marginTop: '2em' }}>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            El producto no puede ser Creado, asegúrese que los campos e imagen se hayan llenado correctamente
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="container" style={{ width: '100%', opacity: opacity }}>
                        <div class="row">
                            <div class="col-12" style={{ paddingTop: '0.5em' }}>
                                <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em' }}>Datos del Producto</span>
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom value={name} setValue={setName} col="col-12" width="100%" placeholder="Ingresa nombre del producto" label="Nombre del Producto (max 50 caracteres)*" required product />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={description} setValue={setDescription} col="col-12" width="100%" placeholder="Ingresa descripción del producto" label="Descripción del Producto *" required component="textarea" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={additional} setValue={setAdditional} col="col-12" width="100%" placeholder="Ingresa información adicional del Producto" label="Información Adicional" component="textarea" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={sku} setValue={setSKU} col="col-12" width="100%" placeholder="Ingresa SKU del producto" label="SKU *" required />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={limit} setValue={setLimit} col="col-12" width="100%" placeholder="Ingresa límite por transacción" label="Límite *" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={price} setValue={setPrice} col="col-12" width="100%" placeholder="Ingresa el precio del producto" label="Precio (Q) *" required money />
                        </div>
                        <div class="row">
                            <SelectCustom setValue={setType} width="100%" options={types} select={type} component="select" col="col-12" label="Tipo" />
                        </div>
                        <div class="row">
                            <SelectCustom setValue={setUnit} width="100%" options={units} select={unit} component="select" col="col-12" name="unit" label="Unidad" />
                        </div>
                        <div class="row" style={{ marginTop: '1em' }}>
                            <div class="col-12" style={{ paddingTop: '0.5em' }}>
                                <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em' }}>Imagen del Producto</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <FileButton title="Logo Producto" setValue={setImage} id="productimage" />
                            </div>
                        </div>
                        <BottomToolBar buttons={[{ auth: true, action: CreateProduct, name: 'Crear', disabled: disable }]} />
                        <div class="row" style={{ textAlign: 'center' }}>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            El producto no puede ser Creado, asegúrese que los campos e imagen se hayan llenado correctamente
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductCreate