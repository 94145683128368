import Button from '@material-ui/core/Button';
import { useEffect, useRef } from "react";
import { Style } from '../Commons/Styles.jsx';
import BottomToolBar from './BottomToolBar.jsx';


const SimpleDialog = (props) => {
    const { title, body, actions, close, setClose, disabled } = props;
    const containerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setClose(true);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, setClose]);

    const OpenDialog = () => {
        setClose(false);
    };

    return (
        <div style={{ width: '95%' }}>
            <div class="row">
                <div class="col-12">
                    <Button variant="outlined" color="primary" style={{ ...Style.Dialog, opacity: disabled ? '0.5' : '1' }} onClick={OpenDialog} disabled={disabled}>
                        {title}
                    </Button>
                </div>
                <div class="container" ref={containerRef} style={{ ...Style.Dialogdiv, display: close ? 'none' : 'block' }}>
                    <h1><p style={{ fontFamily: 'Gothic' }}>{title}</p></h1>
                    <div class="row">
                        <div class="col-12" style={{ minHeight: '300px' }}>
                            {body}
                        </div>
                    </div>
                    {actions &&
                        <BottomToolBar buttons={actions} />
                    }
                </div>
            </div>
        </div>
    );
}

export default SimpleDialog;