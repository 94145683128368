import { useRedirect, useNotify, useDataProvider } from 'react-admin';
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useSelector } from 'react-redux';
import { useLayoutEffect, useState, useEffect, useCallback } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMediaQuery, createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import { StyleTheme } from '../Commons/Styles.jsx';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SimpleDialog from '../Commons/Dialog.jsx';
import ListCustom from '../Commons/ListCustom';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useMediaQueryWidth() {
    const [screenSize, setScreenSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize;
}

const PostCreate = (props) => {
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [width] = useMediaQueryWidth();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [amount, setAmount] = useState(0.00);
    const [nit, setNIT] = useState('');
    const [email, setEmail] = useState('');
    const [data, setData] = useState([]);
    const [state, setState] = useState({});
    const [message, setMessage] = useState('');
    const [openerror, setOpenerror] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [columnsSearch, setColumnsSearch] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [closeDialog, setCloseDialog] = useState(true);

    let sizeopen = open ? width / 1.4 : width / 1.23;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
            typography: {
                fontFamily: 'Gothic',
            },
        },
        esES,
        coreeSES
    );


    const handleQuantityChange = useCallback((event, row) => {
        let index = rows.findIndex((r) => r.id === row.id);
        let quantity = rows[index].quantity;
        rows[index].quantity = parseFloat(event.target.value);
        let increase = event.target.value - quantity;
        let price = rows[index].price * increase;
        let total = parseFloat(event.target.value) * rows[index].price;
        rows[index].total = total;
        setAmount(amount + price);
        setRows([...rows]);
    }, [rows, setRows, amount, setAmount]);


    const handleDiscountChange = useCallback((event, row) => {
        let index = rows.findIndex((r) => r.id === row.id);
        let newvalue = parseFloat(event.target.value);
        rows[index].discount = newvalue;
        let total = (rows[index].price * rows[index].quantity) - newvalue;
        rows[index].total = total;
        let amount = rows.reduce((total, product) => total + (product.quantity * product.price - product.discount), 0);
        setAmount(amount);
        setRows([...rows]);
    }, [rows, setRows, amount, setAmount]);

    useEffect(() => {
        (async () => {
            try {

                setColumnsSearch([
                    { field: 'id', headerName: 'ID', flex: 1 },
                    { field: 'name', headerName: 'Nombre', flex: 1 },
                    { field: 'description', headerName: 'Descripcion', flex: 1 },
                    { field: 'price', headerName: 'Precio', flex: 1 },
                ]);

                setColumns([
                    { field: 'name', headerName: 'Nombre', flex: 1 },
                    { field: 'description', headerName: 'Descripción', flex: 1 },
                    { field: 'price', headerName: 'Precio', flex: 1 },
                    {
                        field: 'discount',
                        headerName: 'Descuento (Q)',
                        flex: 1,
                        renderCell: (params) => (
                            <input
                                className="InputFieldTable"
                                type="number"
                                min="0"
                                defaultValue={params.value || 0}
                                onChange={(e) => handleDiscountChange(e, params.row)}
                            />
                        ),
                    },
                    {
                        field: 'quantity',
                        headerName: 'Cantidad',
                        flex: 1,
                        renderCell: (params) => (
                            <input
                                className="InputFieldTable"
                                type="number"
                                min="1"
                                defaultValue={params.value || 1}
                                onChange={(e) => handleQuantityChange(e, params.row)}
                            />
                        ),
                    },
                    { field: 'total', headerName: 'Total', flex: 1 },
                ]);
            }
            catch {
            }
        })();
    }, [handleQuantityChange, handleDiscountChange]);

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleProductSearch = async (props) => {
        if (!props || props.length === 0) {
            return;
        }

        setSelectedRows(props);

        var products = await dataProvider.getMany('activeproducts/many', { ids: props });
        let updatedProducts = products.data.map(product => ({
            ...product,
            quantity: 1,
            total: product.price,
        }));

        let amount = updatedProducts.reduce((total, product) => total + product.quantity * product.price, 0);
        setAmount(amount);
        setRows(updatedProducts);
    }

    let search = <SimpleDialog title="Productos" body={<ListCustom columns={columnsSearch} resource='activeproducts' selection={true} setSelectedRows={handleProductSearch} selectedRows={selectedRows} />} maxWidth='md' close={closeDialog} setClose={setCloseDialog} />;


    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const CreateRequest = async () => {
        if (rows.length === 0) {
            setMessage("No se pudo crear la factura sin productos asociados")
            handleClickError();
            return;
        }

        if (!Validate(nit, { nit: true, required: true }) || !Validate(email, { email: true, required: true })) {
            setMessage("No se pudo crear la factura, revise que todos los campos esten ingresados correctamente")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            BillingTaxId: nit,
            BillingEmail: email,
            Detail: rows,
        }

        dataProvider.create('invoice', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                redirect('/invoice/' + response.data.id + '/show');
                notify('Factura creada correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo crear la factura")
                handleClickError()
            })
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit" style={{ opacity: opacity }}>
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div>
                        <div class="row">
                            <InputFieldCustom value={nit} setValue={setNIT} col="col-6" width="95%" placeholder="Ingresa número de nit" label="Número de NIT" required nit />
                            <InputFieldCustom value={email} setValue={setEmail} col="col-6" width="95%" placeholder="Ingresa correo electrónico de facturación" label="Correo electrónico" required email />
                        </div>
                        <div class="row">
                            <InputFieldCustom col="col-6" width="100%" component="search" search={search} nolabel />
                        </div>
                        <div class="row">
                            <div class="col-12" style={{ textAlign: 'right', marginTop: '1em' }}>
                                <div>Total : Q{amount}</div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-12" style={{ height: 400, width: '100%' }}>
                                <ThemeProvider theme={theme}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 50, 100]}
                                        disableRowSelectionOnClick
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                        <BottomToolBar buttons={[
                            { auth: true, action: CreateRequest, name: 'Generar', disable: disable }
                        ]} />
                        <div class="row" style={{ textAlign: 'center', marginBottom: '1em', marginTop: '2em' }}>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            {message}
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="container" style={{ width: '100%', opacity: opacity }}>
                        <div class="row">
                            <InputFieldCustom value={nit} setValue={setNIT} width='100%' col="col-12" placeholder="Ingresa número de nit" label="Número de NIT" required nit />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={email} setValue={setEmail} width='100%' col="col-12" placeholder="Ingresa correo electrónico de facturación" label="Correo electrónico" required email />
                        </div>
                        <div class="row" style={{ marginTop: '1em' }}>
                            <div class="col-12" style={{ textAlign: 'right' }}>
                                <div>Total : Q{amount}</div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-12" style={{ height: 400, width: '100%' }}>
                                <ThemeProvider theme={theme}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 50, 100]}
                                        disableRowSelectionOnClick
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                        <BottomToolBar buttons={[
                            { auth: true, action: CreateRequest, name: 'Generar', disable: disable }]
                        } />
                        <div class="row" style={{ textAlign: 'center' }}>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            {message}
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default PostCreate