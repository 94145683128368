import { useEffect, useState, useCallback } from "react";
import { useDataProvider } from "react-admin";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Validate from '../Commons/Validate';
import InputFieldCustom from '../Commons/InputFieldCustom'
import { StyleTheme, DataGridStyle } from '../Commons/Styles.jsx';
import { useMediaQuery, createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CatalogEdit = (props) => {
    const [name, setName] = useState('');
    const [isStructure, setIsStructure] = useState(false);
    const [isPagePaymentRequest, setIsPagePaymentRequest] = useState(false);
    const [columns, setColumns] = useState([]);
    const [openerror, setOpenerror] = useState(false);
    const [details, setDetails] = useState([]);
    const [fathercatalog, setFatherCatalog] = useState(null);
    const [opensnack, setOpensnack] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [fatherStructure, setFatherStructure] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [authStructure, setAuthStructure] = useState(false);
    const [disableStructure, setDisableStructure] = useState(true);
    const [detailId, setDetailId] = useState(null);
    const [isFilter, setIsFilter] = useState(false);
    const [fatherDetails, setFatherDetails] = useState([]);
    const dataProvider = useDataProvider();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const history = useHistory();

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const createRowData = (index) => {
        return {
            id: index,
            code: "",
            value: "",
            key: null,
            fatherid: null,
        };
    }

    const addRow = () => {
        setDetails([...details, createRowData(details.length)]);
    }

    const Delete = () => {
        let values = details.filter((item) => !selectedRows.includes(item.id));
        setDetails(values);
    }

    const Deleted = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.delete('catalogs', { id: props.id })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/catalogs');
            })
            .catch(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                alert("No se ha podido eliminar el catalogo");
            });
    }

    const Update = async () => {

        if (!Validate(name, { required: true })) {
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var data = {
            catalogId: fathercatalog,
            name,
            filter: isFilter,
            details,
            isPagePaymentRequest
        }

        dataProvider.update('catalogs', { id: props.id, data: data })
            .then(response => {
                let values = response.data.detalles.map((item, index) => ({
                    id: index,
                    code: item.codigo,
                    value: item.valor,
                    key: item.id,
                    father: item.catalogoDetalle ? item.catalogoDetalle.valor : "",
                    fatherid: item.catalogoDetalleId
                }));

                setDetails(values);
                handleClick()
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClickError()
            })
    }

    const EditStructure = () => {
        history.push('/structures/' + detailId + '/show');
    }

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const EditCell = useCallback((event, params, field) => {
        details[params.id][field] = event.target.value;
        setDetails([...details]);
    }, [details, setDetails]);

    useEffect(() => {
        (async () => {
            try {
                let columns = [
                    {
                        field: 'fatherid',
                        headerName: 'Padre',
                        headerAlign: 'center',
                        align: 'center',
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <select
                                    className="InputFieldTable"
                                    onChange={(e) => EditCell(e, params, 'fatherid')}
                                >
                                    {
                                        fatherDetails.map((item) => (
                                            <option value={item.id} selected={params.value === item.id}>{item.valor}</option>
                                        ))
                                    }
                                </select>
                            )
                        },
                    },
                    {
                        field: 'code',
                        headerName: 'Codigo',
                        headerAlign: 'center',
                        align: 'center',
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <input
                                    className="InputFieldTable"
                                    defaultValue={params.value}
                                    onChange={(e) => EditCell(e, params, 'code')}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ') {
                                            e.stopPropagation();
                                        }

                                        if (e.key === 'Enter') {
                                            e.stopPropagation();
                                            e.target.blur();
                                        }
                                    }}
                                />
                            )
                        },
                    },
                    {
                        field: 'value',
                        headerName: 'Valor',
                        headerAlign: 'center',
                        align: 'center',
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <input
                                    className="InputFieldTable"
                                    defaultValue={params.value}
                                    onChange={(e) => EditCell(e, params, 'value')}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ') {
                                            e.stopPropagation();
                                        }

                                        if (e.key === 'Enter') {
                                            e.stopPropagation();
                                            e.target.blur();
                                        }
                                    }}
                                />
                            )
                        }
                    }
                ];

                setColumns(columns);
            }
            catch {
            }
        })();
    }, [EditCell]);

    useEffect(() => {
        Upload();
    }, []);

    const Upload = async () => {
        const { data } = await dataProvider.getOne('catalogs', { id: props.id });
        historyTracker.addPath(history.location.pathname, data.catalog.nombre, 1);
        let values = data.catalog.detalles.map((item, index) => ({
            id: index,
            code: item.codigo,
            value: item.valor,
            key: item.id,
            father: item.catalogoDetalle ? item.catalogoDetalle.valor : "",
            fatherid: item.catalogoDetalleId
        }));

        setName(data.catalog.nombre);
        setIsFilter(data.catalog.filtro);
        setIsStructure(data.catalog.isStructure);
        setIsPagePaymentRequest(data.catalog.isPagePaymentRequest);
        setFatherStructure(data.catalog.structureId);
        setAuthStructure(data.catalog.structureId != null);
        setFatherCatalog(data.catalog.catalogo ? data.catalog.catalogo.nombre : null);
        setFatherDetails(data.catalog.catalogo ? data.catalog.catalogo.detalles : []);
        setDetails(values);
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit" style={{ opacity: opacity }}>
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div>
                        <div class="row">
                            <div class="col-2">
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    color="primary"
                                    control={
                                        <Switch
                                            checked={isFilter}
                                            onChange={() => setIsFilter(!isFilter)}
                                            name="checkedFact"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Filtro"}</span>}
                                />
                            </div>
                            {fatherStructure == null &&
                                <div class="col-2">
                                    <FormControlLabel
                                        style={{ fontFamily: 'Gothic' }}
                                        control={
                                            <Switch
                                                checked={isStructure}
                                                onChange={() => null}
                                                name="checkedFact"
                                                color="primary"
                                            />
                                        }
                                        label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Estructura"}</span>}
                                    />
                                </div>
                            }

                            <div class="col-2">
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    control={
                                        <Switch
                                            checked={isPagePaymentRequest}
                                            onChange={() => setIsPagePaymentRequest(!isPagePaymentRequest)}
                                            name="checkedFact"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Pagos"}</span>}
                                />
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom value={name} setValue={setName} col="col-6" width="95%" placeholder="Ingresa nombre de catálogo" label="Nombre de catálogo" required />
                            {fathercatalog != null &&
                                <InputFieldCustom value={fathercatalog} setValue={() => { }} width="95%" readonly={true} col="col-6" label="Padre" name="catalogoId" />
                            }
                        </div>
                        <div class="row" style={{ width: '100%' }}>
                            <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={details}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            checkboxSelection={true}
                                            onRowSelectionModelChange={(ids) => {
                                                if (fatherStructure != null) {
                                                    if (ids.length <= 1) {
                                                        setSelectedRows(ids);
                                                    }

                                                    if (ids.length === 1) {
                                                        setDisableStructure(false);
                                                        let item = details.find((item) => item.id === ids[0]);
                                                        setDetailId(item.key);
                                                    } else {
                                                        setDisableStructure(true);
                                                    }
                                                } else {
                                                    setSelectedRows(ids);
                                                }
                                            }}
                                            rowSelectionModel={selectedRows}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            disableRowSelectionOnClick
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </div>
                        <div class="row">
                            <div>
                                <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                    <div>
                                        <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                            La información del catálogo ha sido actualizada
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            La información del catálogo no se ha podido actualizar
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div class="row">
                            <div class="col-9"></div>
                            <div class="col-2">
                                <div style={{ marginTop: '0em', marginLeft: '-2rem' }}>
                                    <FormControlLabel
                                        style={{ fontFamily: 'Gothic' }}
                                        control={
                                            <Switch
                                                checked={isFilter}
                                                onChange={() => setIsFilter(!isFilter)}
                                                name="checkedFact"
                                                color="primary"
                                            />
                                        }
                                        label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Filtro"}</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom value={name} setValue={setName} col="col-12" width="100%" placeholder="Ingresa nombre de catálogo" label="Nombre de catálogo" required />
                        </div>
                        {fathercatalog != null &&
                            <div class="row">
                                <InputFieldCustom value={fathercatalog} setValue={() => { }} width="95%" readonly={true} col="col-12" label="Padre" name="catalogoId" />
                            </div>
                        }
                        <div class="row">
                            <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={details}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            checkboxSelection={true}
                                            disableMultipleSelection={true}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </div>
                        <div class="row">
                            <div>
                                <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                    <div>
                                        <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                            La información del catálogo ha sido actualizada
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            La información del catálogo no se ha podido actualizar
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                )}
                <div class="row" style={{ marginTop: "1em" }}>
                    <div class="col-12">
                        <label>Detalles</label>
                    </div>
                </div>
                <BottomToolBar buttons={[
                    { auth: true, action: addRow, name: 'Agregar', disable: disable },
                    { auth: true, action: Delete, name: 'Borrar', disable: disable },
                    { auth: authStructure, action: EditStructure, name: 'Estructura', disable: disableStructure },
                ]} />
                <div class="row" style={{ marginTop: "1em" }}>
                    <div class="col-12">
                        <label>Catálogo</label>
                    </div>
                </div>
                <BottomToolBar buttons={[
                    { auth: true, action: Update, name: 'Guardar', disable: disable },
                    { auth: true, action: Deleted, name: 'Eliminar', disable: disable },
                ]} />
            </div >
        </div>
    )
};

export default CatalogEdit;