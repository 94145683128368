import StateTextField from '../Commons/StateTextField'
import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useEffect, useState } from "react";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useDataProvider } from "react-admin";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputFieldCustom from '../Commons/InputFieldCustom';
import TypeField from '../Commons/TypeField';
import { Style, StyleTheme, DataGridStyle } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import InfoUrl from '../Commons/InfoUrl';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const bloburl = process.env.REACT_APP_URL_BLOB ?? '';

const RequestShow = (props) => {
    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState('none');
    const [opensnack, setOpensnack] = useState(false);
    const [message, setMessage] = useState('')
    const [openerror, setOpenerror] = useState(false);
    const [checked, setChecked] = useState(false);
    const [flagvoid, setFlagVoid] = useState(false);
    const [columns, setColumns] = useState([]);
    const [state, setState] = useState({});
    const [products, setProducts] = useState([]);
    const [flagTime, setFlagTime] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [authNotification, setAuthNotification] = useState(false);
    const dataProvider = useDataProvider();
    const history = useHistory();
    let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const handleChange = (event) => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.update('requests/processed', { id: props.id })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia se ha actualizado');
                handleClick();
                setChecked(!checked);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
                setOpen(false);
            })
    };

    const Update = async () => {
        setDisplay('block');
        dataProvider.update('wire', { id: props.id })
            .then(response => {
                state.statename = 'Pagado';
                state.color = '#45CB85';
                state.state = 2;
                setState(state);
                setDisplay('none');
                setMessage('La información de su transferencia se ha actualizado');
                handleClick();
                setOpen(false);
            })
            .catch((error) => {
                setDisplay('none');
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
                setOpen(false);
            })
    }

    const SendWebHook = async () => {
        setDisplay('block');
        setDisplayLoading('block');
        setDisable(true);
        setOpacity(0.3);
        dataProvider.update('webhooksuccess', { id: props.id })
            .then(response => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                handleClick();
                setMessage('Información enviada correctamente');
                setOpen(false);
            })
            .catch((error) => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                setMessage('No se ha podido enviar la información');
                handleClickError();
                setOpen(false);
            })
    }

    const SendWhatsApp = async () => {
        setDisplay('block');
        setDisplayLoading('block');
        setDisable(true);
        setOpacity(0.3);
        dataProvider.update('request/sendWhatsapp', { id: props.id })
            .then(response => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                handleClick();
                setMessage('Mensaje enviado correctamente');
                setOpen(false);
            })
            .catch((error) => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                setMessage('No se ha podido enviar el mensaje');
                handleClickError();
                setOpen(false);
            })
    }

    const SendEmail = async () => {
        setDisplay('block');
        setDisplayLoading('block');
        setDisable(true);
        setOpacity(0.3);
        dataProvider.update('request/sendEmail', { id: props.id })
            .then(response => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                handleClick();
                setMessage('Correo enviado correctamente');
                setOpen(false);
            })
            .catch((error) => {
                setOpacity(1);
                setDisplayLoading('none');
                setDisable(false);
                setMessage('No se ha podido enviar el correo');
                handleClickError();
                setOpen(false);
            })
    }

    const Denied = async () => {
        setDisplay('block');
        dataProvider.update('wiredenied', { id: props.id })
            .then(response => {
                state.statename = 'Revisión';
                state.color = '#523D87';
                state.state = 5;
                setState(state);
                setDisplay('none');
                setMessage('La información de su transferencia se ha actulizado');
                handleClick();
                setOpen(false);
            })
            .catch((error) => {
                setDisplay('none');
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
                setOpen(false);
            })
    }

    const Canceled = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.update('requests/cancel', { id: props.id })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia se ha actualizado');
                handleClick();
                state.statename = 'Cancelado';
                state.color = '#FF003C';
                state.state = 3;
                setState(state);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
            })
    }

    const CanceledToBePaid = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.update('requests/cancel/tobepaid', { id: props.id })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia se ha actualizado');
                handleClick();
                state.statename = 'Cancelado';
                state.color = '#FF003C';
                state.state = 3;
                setState(state);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
            })
    }

    const Undo = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.update('requests/undo', { id: props.id, data: null })
            .then(response => {
                setMessage('La información de su transferencia se ha podido actualizar');
                handleClick();
                state.statename = 'Anulado';
                state.color = '#FFBC0A';
                state.state = 7;
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
            .catch(() => {
                setMessage('La información de su transferencia no se ha podido actualizar');
                handleClickError();
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
    }

    const SimpleDialog = ({ id, state }) => {
        return (
            <div clas="container">
                <div class="row" style={Style.SimpleDialog}>
                    <div class="col-12">
                        <Button variant="outlined" color="primary" style={Style.SimpleDialogButton} onClick={handleClickOpen}>
                            Comprobante
                        </Button>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title" ><h3 style={{ fontFamily: 'Gothic' }}>Comprobante</h3></DialogTitle>
                            <DialogContent>
                                <div style={{ display: display }}>
                                    <CircularProgress />
                                    <h2>Validando</h2>
                                </div>
                                <div style={Style.AllWidthHeight}>
                                    <img src={bloburl + id + ".png"} alt="" style={Style.SimpleDialogImage} />
                                </div>
                            </DialogContent>
                            {state === 6 ? (
                                <DialogActions>
                                    <Button style={Style.SimpleDialogButton} onClick={Update} color="primary">
                                        Aceptar
                                    </Button>
                                    <Button style={Style.SimpleDialogButton} onClick={Denied} color="primary">
                                        Denegar
                                    </Button>
                                </DialogActions>
                            ) : (
                                null
                            )
                            }
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        (async () => {
            try {
                let { data } = await dataProvider.getOne(props.resource, { id: props.id });
                historyTracker.addPath(history.location.pathname, data.number, 1);
                var state = data;

                switch (data.state) {
                    case 0:
                        state.statename = 'Creado';
                        state.color = '#A3D9FF';
                        break;
                    case 1:
                        state.statename = 'Link enviado';
                        state.color = '#4F00E3';
                        break;
                    case 2:
                        state.statename = 'Pagado';
                        state.color = '#45CB85';
                        break;
                    case 3:
                        state.statename = 'Cancelado';
                        state.color = '#FF003C';
                        break;
                    case 4:
                        state.statename = 'Reversado';
                        state.color = '#FFE347';
                        break;
                    case 5:
                        state.statename = 'Denegado';
                        state.color = '#F2545B';
                        break;
                    case 6:
                        state.statename = 'Por aprobar';
                        state.color = '#5300E3';
                        break;
                    case 7:
                        state.statename = 'Anulado';
                        state.color = '#FFBC0A';
                        break;
                    case 9:
                        state.statename = 'Agotado';
                        state.color = '#EEAF40';
                        break;
                    case 10:
                        state.statename = 'Revisión';
                        state.color = '#523D87';
                        break;
                    case 11:
                        state.statename = 'Fraudulento';
                        state.color = '#D55134';
                        break;
                    default:
                        state.statename = 'Por Cobrar';
                        state.color = '#69B8C2';
                        break;
                }

                setState(state);

                let productsinfo = data.authorizationRequest.productLines.map((item, index) => ({
                    id: index,
                    quantity: item.quantity,
                    unit: item.unit,
                    name: item.name,
                    price: item.price,
                    discount: item.discount,
                    total: (item.price * item.quantity) - item.discount
                }));

                let typetransaction = data.authorizationRequest.type;

                setProducts(productsinfo);
                setChecked(data.viewed);
                setFlagVoid(typetransaction === 0 || typetransaction === 2);
                setAuthNotification(data.state === 12 || data.state === 0);

                if (isSmall) {
                    setColumns([
                        {
                            field: 'quantity',
                            headerName: 'Cantidad',
                            editable: false,
                            flex: 1,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'name',
                            headerName: 'Nombre',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'total',
                            headerName: 'Total (Q)',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        }
                    ]);
                } else {

                    setColumns([
                        {
                            field: 'quantity',
                            headerName: 'Cantidad',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'name',
                            headerName: 'Nombre',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'price',
                            headerName: 'Precio (Q)',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'total',
                            headerName: 'Total (Q)',
                            flex: 1,
                            editable: false,
                            headerAlign: 'center',
                            align: 'center',
                        }
                    ]);
                }

                let actualdate = new Date(data.created);

                let year1 = actualdate.getFullYear();
                let day1 = actualdate.getDate() > 9 ? actualdate.getDate() : '0' + actualdate.getDate();
                let month1 = actualdate.getMonth() + 1 > 9 ? actualdate.getMonth() + 1 : '0' + (actualdate.getMonth() + 1);

                let startdate = Date.parse(year1 + '-' + month1 + '-' + day1 + 'T00:00:00.0000000');
                let enddate = startdate + (3600000 * 24);

                let datetime = new Date();
                let datetimeactual = datetime.getTime() + (3600000 * data.offset);

                setFlagTime(datetimeactual < enddate || data.authorizationRequest.type === 1);
            }
            catch (error) {
            }
        })();
    }, [isSmall]);

    return (
        <div class="container" style={{ opacity: opacity }}>
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {!isSmall ? (
                <div class="Loading" style={{ display: displayLoading }}>
                    <CircularProgress size="15rem" />
                </div>) : (
                <div class="LoadingMobile" style={{ display: displayLoading }}>
                    <CircularProgress size="15rem" />
                </div>
            )}
            {!isSmall ? (
                <div>
                    <div class="row">
                        <div class="col-1">
                            <span style={Style.LabelState}> Estado: </span>
                        </div>
                        <div class="col-2" style={{ textAlign: 'center' }}>
                            <StateTextField state={state.statename} color={state.color} fontsize="0.85em" width='8em' height="2em" paddingTop="2%" />
                        </div>
                        <div class="col-3">
                            <div class="row">
                                <div class="col-2">
                                    <span style={Style.LabelState}> Tipo: </span>
                                </div>
                                <div class="col-6" style={{ marginTop: state.authorizationRequest?.type < 2 ? '-0.7em' : '-0.2em' }}>
                                    <TypeField
                                        type={state.authorizationRequest?.type}
                                        width={state.authorizationRequest?.type < 2 ? '3em' : '1.5em'}
                                        height={state.authorizationRequest?.type < 2 ? '3em' : '1.5em'} />
                                </div>
                            </div>
                        </div>
                        <div class="col-3"></div>
                        {state.authorizationRequest?.deliveryPrice > 0 ? (
                            <div class='col-2'>
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleChange}
                                            name="checkedFact"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Procesado"}</span>}
                                />
                            </div>) : null
                        }

                    </div >
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingAddressLine1} label="Dirección" />
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingAddressCity} label="Ciudad" />
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingEmail} label="Correo" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingForename} label="Nombre" />
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingSurname} label="Apellido" />
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.billingPhone} label="Teléfono" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.amount} label="Monto" />
                        {state.authorizationRequest?.type === 1 ?
                            (
                                <InputFieldCustom col='col-4' width='100%' value={state.authorizationRequest?.referenceWire} label="Transferencia" />
                            ) : (
                                <InputFieldCustom col='col-4' width='100%' value={state.authorizationResponse ? state.authorizationResponse.transactionId : ""} label="Transacción" />
                            )
                        }

                        <InputFieldCustom col='col-4' width='100%' value={state.authorizationResponse ? state.authorizationResponse.authorizationCode : ""} label="Autorización" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' value={state.created} label="Creación" />
                        <InputFieldCustom col='col-4' width='100%' type="url" value={state.link_pickup} label="Track Pickup" />
                        <InputFieldCustom col='col-4' width='100%' type="url" value={state.link_delivery} label="Track Delivery" />
                    </div>
                    <BottomToolBar buttons={
                        [
                            { auth: true, action: SendWebHook, name: 'Información', disabled: false },
                            { auth: true, name: 'Factura', disabled: false, href: state.pdf },
                            { auth: authNotification, action: SendEmail, name: 'Correo', disabled: false },
                            { auth: authNotification, action: SendWhatsApp, name: 'Whatsapp', disabled: false },

                        ]} />
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={products}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>
                    {
                        state.authorizationRequest?.type === 1 ?
                            (
                                <SimpleDialog id={state.authorizationRequest?.id} state={state.state} />
                            ) : (null)
                    }
                    {
                        state.state === 2 && flagTime && (!checked || state.authorizationRequest?.deliveryPrice === 0) ? (
                            <BottomToolBar buttons={[{ auth: true, action: Undo, name: 'Anular', disabled: false }]} />
                        ) : (null)
                    }
                    {
                        state.state === 0 ? (
                            <BottomToolBar buttons={[{ auth: true, action: Canceled, name: 'Cancelar', disabled: false }]} />
                        ) : state.state === 12 ? (
                            <BottomToolBar buttons={[{ auth: true, action: CanceledToBePaid, name: 'Cancelar', disabled: false }]} />
                        ) : (null)
                    }
                    <div class="row" style={{ textAlign: 'center', marginBottom: '1em', marginTop: '2em' }}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            ) : (
                <div class="container" style={{ marginTop: '1.5em' }}>
                    <div class="row" style={{ marginBottom: '1.5em' }}>
                        <div class="col-2" style={{ marginLeft: '0.5em' }}>
                            <span style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em' }}> Estado: </span>
                        </div>
                        <div class="col-6" style={{ textAlign: 'center', marginTop: '0.3em' }}>
                            <StateTextField state={state.statename} color={state.color} fontsize="0.85em" width='8em' />
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: '2em' }}>
                        <div class="col-2" style={{ marginLeft: '0.5em' }}>
                            <span style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em' }}> Tipo: </span>
                        </div>
                        <div class="col-6" style={{ marginTop: state.authorizationRequest?.type < 2 ? '-0.7em' : '-0.2em' }}>
                            <TypeField
                                type={state.authorizationRequest?.type}
                                width={state.authorizationRequest?.type < 2 ? '3em' : '1.5em'}
                                height={state.authorizationRequest?.type < 2 ? '3em' : '1.5em'} />
                        </div>
                    </div>
                    {state.authorizationRequest?.deliveryPrice > 0 ? (
                        <div class="row">
                            <div class="col-12" style={{ marginTop: '2em', marginLeft: '0.5em' }}>
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleChange}
                                            name="checkedFact"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Procesado"}</span>}
                                />
                            </div>
                        </div>) : null}

                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingAddressLine1} label="Dirección" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingAddressCity} label="Ciudad" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingEmail} label="Correo" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingPhone} label="Teléfono" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingForename} label="Nombre" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.billingSurname} label="Apellido" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.amount} label="Monto" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.created} label="Creación" />
                    </div>
                    <div class="row">
                        {state.authorizationRequest?.type === 1 ?
                            (
                                <InputFieldCustom width='100%' col="col-12" value={state.authorizationRequest?.referenceWire} label="Transferencia" />
                            ) : (
                                <InputFieldCustom width='100%' col="col-12" value={state.authorizationResponse ? state.authorizationResponse.transactionId : ""} label="Transacción" />
                            )
                        }
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" value={state.authorizationResponse ? state.authorizationResponse.authorizationCode : ""} label="Autorización" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' type="url" col="col-12" value={state.link_pickup} label="Track Pickup" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' type="url" col="col-12" value={state.link_delivery} label="Track Delivery" />
                    </div>
                    <BottomToolBar buttons={[{ action: SendWebHook, name: 'Información' }]} disabled={disable} />
                    <div class="row" style={{ marginTop: '1em' }}>
                        <InfoUrl value={state.pdf} col={'col-12'} disable={disable} />
                    </div>
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={products}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>
                    {state.authorizationRequest?.type === 1 ?
                        (
                            <SimpleDialog id={state.authorizationRequest?.id} state={state.state} />
                        ) : (
                            <div></div>
                        )
                    }
                    {
                        state.state === 2 && flagTime && (!checked || state.authorizationRequest?.deliveryPrice === 0) ? (
                            <BottomToolBar buttons={[{ action: Undo, name: 'Anular' }]} disabled={disable} />) : (null)
                    }
                    {
                        state.state === 0 ? (
                            <BottomToolBar buttons={[{ auth: true, action: Canceled, name: 'Cancelar', disabled: false }]} />
                        ) : state.state === 12 ? (
                            <BottomToolBar buttons={[{ auth: true, action: CanceledToBePaid, name: 'Cancelar', disabled: false }]} />
                        ) : (null)
                    }
                    <div class="row" style={{ textAlign: 'center' }}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError}>
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default RequestShow