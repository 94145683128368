import { TopToolbar } from 'react-admin';
import { useListContext, sanitizeListRestProps, useDataProvider } from 'react-admin';
import { useMediaQuery, createTheme } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Style } from '../Commons/Styles.jsx';
import * as XLSX from 'xlsx';

const urlcatalog = process.env.REACT_APP_URL ? process.env.REACT_APP_URL + 'catalog/' : '';

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    create,
    catalog,
    download,
    calendar,
    ...rest
  } = props;

  const {
    currentSort,
    resource,
    filterValues,
    basePath
  } = useListContext();

  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { getIdTokenClaims } = useAuth0();
  const dataProvider = useDataProvider();
  const [state, setState] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        var claims = await getIdTokenClaims();
        const { data } = await dataProvider.getOne('profile', { id: claims.sub });
        setState(data);
      }
      catch {
      }
    })();
  }, [dataProvider, getIdTokenClaims]);

  async function exportToExcel(filename, resource, sort, filterValues) {
    let data = await dataProvider.getList(resource, { pagination: { page: 1, perPage: 1000 }, sort, filter: filterValues });
    let columns = [];
    for (let key in data.data[0]) {
      columns.push(key)
    }
    const ws = XLSX.utils.json_to_sheet(data.data, { header: columns });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <TopToolbar  {...sanitizeListRestProps(rest)}>
      {catalog ? (
        <Button
          color="primary"
          style={Style.ToolBarButton}
          icon={<noicon />}
          onClick={() => { navigator.clipboard.writeText(urlcatalog + state.id) }}>
          Catálogo
        </Button>) : (null)}
      {!isSmall && download ? (
        <Button
          onClick={() => exportToExcel('data', resource, currentSort, filterValues)}
          style={Style.ToolBarButton}>
          Exportar
        </Button>
      ) : (
        null
      )}
      {create ? (
        <Button
          href={'#' + basePath + '/create'}
          color="primary"
          style={Style.ToolBarButton}>
          Crear
        </Button>
      ) : (null)}

      {calendar ? (
        <Button
          color="primary"
          style={Style.ToolBarButton}
          onClick={() => { window.open("https://calendly.com/event_types/user/me", "_blank") }}>
          Evento
        </Button>

      ) : (null)}
    </TopToolbar>
  );
};

export default ListActions;