import { useEffect, useState } from "react";
import { useMediaQuery } from '@material-ui/core';
import { useDataProvider } from "react-admin";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import StateTextField from '../Commons/StateTextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputFieldCustom from '../Commons/InputFieldCustom';
import { StyleTheme, DataGridStyle } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import InfoUrl from '../Commons/InfoUrl';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import BreadCrumbCustom from '../Commons/BreadCrum';
import BottomToolBar from '../Commons/BottomToolBar';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InvoiceShow = (props) => {

    var isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const history = useHistory();

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    useEffect(() => {
        (async () => {
            try {
                const { data } = await dataProvider.getOne('invoice', { id: props.id });
                historyTracker.addPath(history.location.pathname, data.id, 1);
                var state = data;

                switch (data.state) {
                    case 0:
                        state.color = '#A3D9FF';
                        state.statename = 'Creado';
                        break;
                    case 1:
                        state.color = '#FF003C';
                        state.statename = 'Anulada';
                        break;
                    case 2:
                    default:
                        state.color = '#F2545B';
                        state.statename = 'Nota';
                        break;
                }

                setState(state);

                var products = data.productLines.map((item, index) => ({
                    id: index,
                    name: item.name,
                    quantity: item.quantity,
                    unit: item.unit,
                    description: item.description,
                    price: item.price,
                    discount: item.discount,
                    total: (item.price * item.quantity) - item.discount
                }));

                setProducts(products);

                setColumns([
                    {
                        field: 'quantity',
                        headerName: 'Cantidad',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'name',
                        headerName: 'Nombre',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'price',
                        headerName: 'Precio (Q)',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'discount',
                        headerName: 'Descuento (Q)',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'total',
                        headerName: 'Total (Q)',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    }
                ]);
            }
            catch (error) {
            }
        })();
    }, []);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const Undo = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block')
        dataProvider.update('invoice/undo', { id: props.id, data: null })
            .then(response => {
                state.statename = 'Anulado';
                state.color = '#FF003C';
                state.state = 1;
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClick();
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClickError();
            })
    }

    return (
        <div class="container" style={{ opacity: opacity }}>
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {!isSmall ? (
                <div class="Loading" style={{ display: displayLoading }}>
                    <CircularProgress size="15rem" />
                </div>) : (
                <div class="LoadingMobile" style={{ display: displayLoading }}>
                    <CircularProgress size="15rem" />
                </div>
            )}
            {!isSmall ? (
                <div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2">
                                    <span style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em' }}> Estado:</span>
                                </div>
                                <div class="col-2" style={{ textAlign: 'center' }}>
                                    <StateTextField state={state.statename} color={state.color} fontsize="0.85em" width='8em' height="2em" paddingTop="2%" />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' setValue={() => { }} value={state.created} label="Fecha" />
                        <InputFieldCustom col='col-4' width='100%' setValue={() => { }} value={state.authId} label="Autorización" />
                        <InputFieldCustom col='col-4' width='100%' setValue={() => { }} value={state.taxId} label="NIT" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col='col-4' width='100%' setValue={() => { }} value={state.name} label="Nombre" />
                        <InputFieldCustom col='col-4' width='100%' setValue={() => { }} value={state.amount} label="Total" />
                        <InfoUrl value={state.fileUrl} style={{ marginTop: '3em' }} />
                    </div>
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={products}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>
                    {
                        state.state === 0 ? (
                            <BottomToolBar buttons={[{ auth: true, action: Undo, name: 'Anular', disable: disable }]} />
                        ) : (null)
                    }
                    <div>
                        <div>
                            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                                <div>
                                    <Alert onClose={handleClose} severity="success" className={classes.myAlert}>
                                        La factura ha sido Anulada
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                        La factura no ha podido ser Anulada
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            ) : (
                <div class="container" style={{ marginTop: '1.5em' }}>

                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" setValue={() => { }} value={state.created} label="Fecha" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" setValue={() => { }} value={state.authId} label="Autorización" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" setValue={() => { }} value={state.taxId} label="NIT" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" setValue={() => { }} value={state.name} label="Nombre" />

                    </div>
                    <div class="row">
                        <InputFieldCustom width='100%' col="col-12" setValue={() => { }} value={state.amount} label="Total" />

                    </div>
                    <div class="row">
                        <InfoUrl col="col-12" value={state.fileUrl} />
                    </div>
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={products}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>
                    {
                        state.state === 0 ? (
                            <BottomToolBar buttons={[{ auth: true, action: Undo, name: 'Anular', disable: disable }]} />
                        ) : (null)
                    }
                    <div>
                        <div>
                            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                                <div>
                                    <Alert onClose={handleClose} severity="success" className={classes.myAlert} variant="outlined" >
                                        La información de su perfil ha sido actualizada
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                        La información de su perfil no se ha podido actualizar
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default InvoiceShow