const StyleTheme = {
  fields: {
    textAlign: 'center'
  },
  bool: {
    width: '100%',
    textAlign: 'center',
  },
  customSelectInput: {
    minWidth: '90%',
    marginRight: '1em'
  },
  customSelectInputSide: {
    minWidth: '100%'
  },
  input: {
    "& div.filter-field": {
      width: '16.5%'
    },
  },
  inputSide: {
    "& div.filter-field": {
      width: '100%'
    },
  },
  root: {
    "& div.react-grid-Container": {
      color: "gray",
      textAlign: 'center',
      verticalAlign: 'middle',
      fontFamily: 'Gothic',
      paddingTop: '1em',
      fontSize: '0.85em',
      borderCollapse: 'collapse',
    },
    amount: {
      fontFamily: 'Gothic'
    }
  },
  header: {
    "& div.react-grid-Header": {
      color: "red",
      textAlign: 'center',
      verticalAlign: 'middle',
      fontFamily: 'Gothic',
      paddingTop: '1em'
    },
  },
  dataTitle: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em'
  },
  dataInfo: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '1em',
    color: '#262150'
  },
  imageprofile: {
    width: 70,
    height: 50,
  },
  myAlert: {
    backgroundColor: '#6D00E6',
    color: 'white',
    fontFamily: 'Gothic',
    borderColor: '#6D00E6'
  },
  myAlertError: {
    color: 'white',
    fontFamily: 'Gothic'
  },
  dropZone: {
    fontFamily: 'Gothic',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF00',
    width: '3em',
    height: '3em',
    color: '#e7e7e7',
    marginTop: '-5.05em',
    marginLeft: '0.68em',
    fontSize: '0.9em',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '1%',
    objectFit: 'contain',
  },
  phone: {
    "& input.PhoneInputInput": {
      fontFamily: 'Gothic',
      border: '2px solid',
      borderColor: '#EFEFEF',
      borderRadius: '7px',
      fontWeight: 'bold',
      width: '100%',
      fontSize: '1em',
      height: '2.7em',
      paddingInlineStart: '1em',
    },
  },
}

const Style = {
  fieldList: {
    color: 'black',
    fontFamily: 'Gothic'
  },
  SelectCustom: {
    fontFamily: 'Gothic',
    border: '2px solid #EFEFEF',
    borderRadius: '7px',
    fontWeight: 'bold',
    fontSize: '0.85em',
    height: '2.7em',
    paddingInlineStart: '1em',
    marginTop: '0em'
  },
  MenuLogo: {
    maxWidth: '1.3em',
    maxHeight: '1.3em'
  },
  Flags: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em',
  },
  FilterLabel: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: '#191268',
    width: '100%'
  },
  AllWidth: {
    width: '100%'
  },
  BreadCrum: {
    fontFamily: 'Gothic'
  },
  DataGrid: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: '1em',
    paddingright: '1em',
  },
  ReportTitle: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1em'
  },
  InputFieldLabel: {
    fontFamily: 'BoldGothic',
    fontWeight: 1000,
    color: '#191268',
    paddingLeft: '1em',
    fontSize: '0.85em',
    minWidth: "100%",
    borderColor: "white"
  },
  InputFieldLabelMobile: {
    fontFamily: 'BoldGothic',
    fontWeight: 1000,
    color: '#191268',
    paddingLeft: '1em',
    fontSize: '0.85em',
    minWidth: "100%",
    borderColor: "white",
    margin: '0 auto'
  },
  ToolBarButton: {
    background: 'white',
    width: '100%',
    color: '#191268',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderColor: '#191268',
    border: "2px solid #191268",
    borderRadius: "7px",
    fontSize: "0.75rem",
    marginRight: "0.5em",
    height: "2.5rem"
  },
  Search: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    marginTop: `27px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    left: "30%",
    marginLeft: "0",
    fontFamily: 'Gothic'
  },
  PostPaginatioinButton: {
    fontFamily: 'Gothic',
    color: "#191268"
  },
  Label: {
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: '#5300E3',
    marginLeft: '2.5em',
    marginTop: '2em',

  },
  DataGridCustome: {
    width: '99.5%',
    marginLeft: '0.1em'
  },
  SaveButton: {
    background: '#191268',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px',
    color: 'white',
    width: '100%',
    border: "2px solid #191268"
  },
  DeleteButton: {
    background: 'white',
    color: '#191268',
    width: '100%',
    border: "2px solid #191268",
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px'
  },
  Alert: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '2em'
  },
  AlertMessage: {
    color: 'white',
    fontFamily: 'Gothic'
  },
  ExpandListRow: {
    marginTop: '1em'
  },
  ExpandListDate: {
    border: 'white',
    fontWeight: 'bold'
  },
  MenuItem: {
    color: '#191268',
    fontWeight: 'bold',
    fontSize: '1em',
    marginTop: '2em',
    fontFamily: 'Gothic'
  },
  UserMenuItem: {
    color: '#191268',
    fontWeight: 'bold',
    fontSize: '1em',
    fontFamily: 'Gothic'
  },
  Dialog: {
    background: '#191268',
    fontSize: "0.85em",
    color: 'white',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px',
    width: '100%',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    marginTop: '-3.5em',
    marginLeft: '7em'
  },
  popover2: {
    position: 'absolute',
    zIndex: '2',
    marginTop: '-3.5em',
    marginLeft: '-16.8em'
  }, cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  Map: {
    fontWeight: 'bold',
    minWidth: '80%',
    fontSize: '0.85em',
    height: '2em',
    paddingLeft: '1em'
  },
  LabelColor: {
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: 'black',
    order: 2,
    margin: '0 auto'
  },
  CenterFlex: {
    alignItems: 'center',
    display: 'flex'
  },
  PositionColor: {
    position: 'relative',
    marginTop: '1.3em'
  },
  PositionButtonColor: {
    margin: 0,
    position: 'absolute',
    left: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)'
  },
  PositionButtonColor2: {
    order: 2,
    margin: '0 auto',
    borderRadius: '7px',
    width: '5em',
    height: '2em',
    color: '#ffffff',
    textTransform: 'none'
  },
  LabelLogo: {
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: 'black',
    marginLeft: '2.5em'
  },
  EditButton: {
    color: 'white',
    backgroundColor: '#6D00E6',
    marginTop: '1.2em',
    borderRadius: '50%',
    padding: '0.3em',
    marginLeft: '0.4em',
    height: '1.2em',
    width: '1.2em'
  },
  LabelMobile: {
    marginTop: '1.2em',
    marginLeft: '2.5em',
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: '#6d00e6'
  },
  TextFieldList: {
    color: 'black',
    fontFamily: 'Gothic'
  },
  InfoUrl: {
    background: 'white',
    color: '#5300E3',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderColor: '#5300E3',
    borderRadius: '7px',
    width: '100%'
  },
  SimpleDialogButton: {
    background: '#5300E3',
    fontSize: "1em",
    color: 'white',
    fontFamily: 'Gothic',
    textTransform: 'none'
  },
  SimpleDialog: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '2em'
  },
  SimpleDialogImage: {
    width: '100%',
    height: '100%',
    borderRadius: '1%',
    objectFit: 'contain'
  },
  AllWidthHeight: {
    width: '100%',
    height: '100%'
  },
  LabelState: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em',
    height: '2em'
  },
  dataTitle: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '1em'
  },
  phone: {
    width: "93%",
    marginLeft: "2%",
    fontFamily: 'BoldGothic',
    marginTop: '-1%',
    fontSize: '0.85em',
    marginBottom: "0%"
  },
  Dialogdiv: {
    position: 'fixed',
    zIndex: '5',
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    maxWidth:'50%'
  }
}

const DataGridStyle = {

  palette: {
    primary: { main: '#191268' },
  },
  typography: {
    fontFamily: 'Gothic',
  },
}

export { StyleTheme, Style, DataGridStyle };