import { List, DateField, FunctionField, DateInput, NumberField, TextInput, SelectInput, TextField } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import TypeField from '../Commons/TypeField'
import PostPagination from '../Commons/PostPagination';
import TextFieldGrid from '../Commons/TextFieldGrid';
import ListActions from '../Commons/ListActions';
import { useDataProvider } from 'react-admin';
import StateTextField from '../Commons/StateTextField'
import { useLayoutEffect, useState, useEffect } from "react";
import { useMediaQuery, createTheme } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import { Style, StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
import historyTracker from '../Commons/historyTracker';
import { useLocation } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';

function useMediaQueryWidth() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
}

const RequestList = (props) => {
  const { getIdTokenClaims } = useAuth0();
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [state, setState] = useState([]);
  const [width] = useMediaQueryWidth();
  const [historyInfo, setHistoryInfo] = useState(historyTracker.getHistory());
  const location = useLocation();
  const hiddencreated = width < 1220;
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const Types = [
    { id: '0', name: 'Tarjeta' },
    { id: '1', name: 'Transferencia' },
    { id: '2', name: 'Cuotas' }
  ];

  const Processed = [
    { id: 'viewed', name: 'Procesado' },
    { id: 'notviewed', name: 'Pendiente' }
  ];

  const States = [
    { id: '0', name: 'Creado' },
    { id: '2', name: 'Pagado' },
    { id: '3', name: 'Cancelado' },
    { id: '5', name: 'Denegado' },
    { id: '6', name: 'Por aprobar' },
    { id: '7', name: 'Anulado' },
    { id: '12', name: 'Por Cobrar' }
  ]

  useEffect(() => {
    (async () => {
      try {
        var claims = await getIdTokenClaims();
        const { data } = await dataProvider.getOne('usercatalogs', { id: claims.sub });
        setState(data);
        historyTracker.cleanHistory();
        historyTracker.addPath(location.pathname, 'Transacciones', 0);
        setHistoryInfo(historyTracker.getHistory());
      }
      catch {
      }
    })();
  }, [dataProvider, getIdTokenClaims]);

  let Filter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"Apellido"}</span>} source="lastName" alwaysOn />,
    <DateInput InputProps={{ disableUnderline: true }} label={<span >{"Fecha de Pago"}</span>} source="date" alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Tipo"}</span>} source="type" choices={Types} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Procesado"}</span>} source="viewed" choices={Processed} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Pago"}</span>} source="state" choices={States} alwaysOn />
  ];

  let CatalogsFilter = state.catalogs ? state.catalogs.map((element) =>
  (
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{element.value}</span>} source={element.value + "Filtro"}
      choices={element.detalles} alwaysOn />
  )) : null;

  let PostFilter = CatalogsFilter !== null ? Filter.concat(CatalogsFilter) : Filter;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyInfo} />
      <div class="row">
        <div class="col-12">
          {isSmall ? (
            <List empty={false} {...props} actions={<ListActions create={true} />} bulkActionButtons={false} pagination={<PostPagination />}>
              <Datagrid {...props} rowClick="show" optimized >
                <TextField source="number" style={Style.TextFieldList} label="Orden" sortable={false} cellClassName={classes.fields} />
                <FunctionField label="Tipo" cellClassName={classes.fields} render={(record) => {
                  switch (record.authorizationRequest.type) {
                    case 0:
                      return <TypeField type={0} />;
                    case 1:
                      return <TypeField type={1} />;
                    case 2:
                      return <TypeField width={'1.5em'} height={'1.5em'} />;
                    case 3:
                      return <TypeField width={'1.5em'} height={'1.5em'} />;
                    default:
                      break;
                  }
                  return `${record.state}`
                }} />
                <NumberField source="authorizationRequest.amount" options={{ style: 'currency', currency: 'GTQ' }} style={Style.TextFieldList} label="Monto" sortable={false} cellClassName={classes.fields} />
              </Datagrid>
            </List>
          ) : (
            <List empty={false} {...props} aside={<FilterSidebar props={props} filters={PostFilter} defaultvalues={{}} />} actions={<ListActions download={true} create={true} />} bulkActionButtons={false} pagination={<PostPagination />}>
              <Datagrid {...props} rowClick="show" optimized>
                <TextField source="number" style={Style.TextFieldList} label="Orden" sortable={false} cellClassName={classes.fields} />
                <FunctionField label="Tipo" cellClassName={classes.fields} render={(record) => {
                  switch (record.authorizationRequest.type) {
                    case 0:
                      return <TypeField type={0} />;
                    case 1:
                      return <TypeField type={1} />;
                    case 2:
                      return <TypeField type={2} width={'1.5em'} height={'1.5em'} />;
                    case 3:
                      return <TypeField type={3} width={'1.5em'} height={'1.5em'} />;
                    default:
                      break;
                  }
                  return `${record.state}`
                }} />
                {hiddencreated ? null :
                  <DateField source="created" style={Style.TextFieldList} label="Creado" showTime={true} sortable={false} cellClassName={classes.fields} />
                }
                <TextFieldGrid source="authorizationRequest.billingForename" label="Nombre" sortable={false} cellClassName={classes.fields} />
                <TextFieldGrid source="authorizationRequest.billingSurname" label="Apellido" sortable={false} cellClassName={classes.fields} />
                <NumberField source="authorizationRequest.amount" options={{ style: 'currency', currency: 'GTQ' }} style={Style.TextFieldList} label="Monto" sortable={false} cellClassName={classes.fields} />
                <FunctionField label="Pago" cellClassName={classes.fields} render={(record) => {
                  switch (record.state) {
                    case 0:
                      return <StateTextField state="Creado" color="#A3D9FF" width="8em" height="2em" paddingTop="3%" />;
                    case 1:
                      return <StateTextField state="Link enviado" color="#4F00E3" width="8em" height="2em" paddingTop="3%" />;
                    case 2:
                      return <StateTextField state="Pagado" color="#45CB85" width="8em" height="2em" paddingTop="3%" />;
                    case 3:
                      return <StateTextField state="Cancelado" color="#FF003C" width="8em" height="2em" paddingTop="3%" />;
                    case 4:
                      return <StateTextField state="Devolucion" color="#FFE347" width="8em" height="2em" paddingTop="3%" />;
                    case 5:
                      return <StateTextField state="Denegado" color="#F2545B" width="8em" height="2em" paddingTop="3%" />;
                    case 6:
                      return <StateTextField state="Por aprobar" color="#5300E3" width="8em" height="2em" paddingTop="3%" />;
                    case 7:
                      return <StateTextField state="Anulado" color="#FFBC0A" width="8em" height="2em" paddingTop="3%" />;
                    case 9:
                      return <StateTextField state="Agotado" color="#EEAF40" width="8em" height="2em" paddingTop="3%" />;
                    case 10:
                      return <StateTextField state="Revisión" color="#523D87" width="8em" height="2em" paddingTop="3%" />;
                    case 11:
                      return <StateTextField state="Fraudulento" color="#D55134" width="8em" height="2em" paddingTop="3%" />;
                    case 12:
                      return <StateTextField state="Por Cobrar" color="#69B8C2" width="8em" height="2em" paddingTop="3%" />;
                    default:
                      break;
                  }
                }} />
              </Datagrid>
            </List>
          )}
        </div>
      </div>
    </div>
  )
};

export default RequestList;