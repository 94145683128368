import Box from '@mui/material/Box';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

const ListCustom = (props) => {
    const { columns, resource, selection, setSelectedRows, filter, selectedRows } = props;
    const dataProvider = useDataProvider();
    const [rows, setRows] = useState([]);

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#191268' },
            },
            typography: {
                fontFamily: 'Gothic',
            },
        },
        esES,
        coreeSES
    );

    useEffect(() => {
        (async () => {
            try {
                const { data } = await dataProvider.getList(resource, {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'id', order: 'ASC' },
                    filter: { ...filter },
                });
                setRows(data);
            } catch (error) {
                console.log(error);
            }
        }
        )();
    }, [dataProvider, resource, filter]);

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <ThemeProvider theme={theme}>
                <DataGrid
                slots={{ toolbar: GridToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    checkboxSelection={selection}
                    onRowSelectionModelChange={(ids) => {
                        if (setSelectedRows) {
                            setSelectedRows(ids);
                        }
                    }}
                    disableRowSelectionOnClick
                    rowSelectionModel={selectedRows}
                />
            </ThemeProvider>
        </Box>
    );

};

export default ListCustom;