import { Style } from './Styles.jsx';
import Button from '@material-ui/core/Button';

function InfoUrl(props) {

    const { col, value, disable, style } = props;
    return (
        <div class={col ?? "col-2"} style={style}>
            {
                !value ? (null) : (
                    <Button href={value} target="_blank" rel="noreferrer" disabled={disable} variant="outlined" style={Style.InfoUrl}>
                        Factura
                    </Button>
                )}
        </div>
    );
}

export default InfoUrl;
