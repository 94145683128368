import { List, Datagrid, SelectInput, TextField, TextInput, NumberField, ImageField } from 'react-admin';
import { useAuth0 } from "@auth0/auth0-react";
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useMediaQuery, createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Style } from '../Commons/Styles.jsx';
import FilterSidebar from '../Commons/FilterSidebar';
import historyTracker from '../Commons/historyTracker';
import { useLocation } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';

const ProductsList = (props) => {
  const [state, setState] = useState([]);
  const [historyInfo, setHistoryInfo] = useState(historyTracker.getHistory());
  const location = useLocation();
  const dataProvider = useDataProvider();
  const { getIdTokenClaims } = useAuth0();

  const theme = createTheme({
    overrides: {
      MuiCard: {
        root: {
          width: '90%',
        },
      }
    }
  })

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const useImageFieldStyles = makeStyles(() => ({
    image: {
      width: !isSmall ? 100 : 75,
      height: !isSmall ? 100 : 75,
      objectFit: 'contain',
    }
  }));

  const imageFieldClasses = useImageFieldStyles();

  useEffect(() => {
    (async () => {
      try {
        var claims = await getIdTokenClaims();
        const { data } = await dataProvider.getOne('usercatalogs', { id: claims.sub });
        setState(data);
        historyTracker.cleanHistory();
        historyTracker.addPath(location.pathname, 'Productos', 0);
        setHistoryInfo(historyTracker.getHistory());
      }
      catch {
      }
    })();
  }, [dataProvider, getIdTokenClaims]);

  let Filter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"Nombre"}</span>} source="name" alwaysOn />
  ];

  let CatalogsFilter = state.catalogs ? state.catalogs.map((element) =>
  (
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{element.value}</span>} source={element.value + "Filtro"}
      choices={element.detalles} alwaysOn />
  )) : null;

  let PostFilter = CatalogsFilter !== null ? Filter.concat(CatalogsFilter) : Filter;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyInfo} />
      {isSmall ? (
        <List autoReload={false} empty={false} {...props} pagination={<PostPagination />} actions={<ListActions create={true} catalog={true} />} bulkActionButtons={false} style={{ marginLeft: "2%" }}>
          <Datagrid  {...props} rowClick="show" style={{ tableLayout: 'fixed', textAlign: 'center' }}  >
            <ImageField src="url" source="url" title="name" label={<span style={Style.dataTitle}>{"Imagen"}</span>} classes={imageFieldClasses} />
            <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="name" />
            <NumberField textAlign='center' style={{ fontFamily: 'Gothic', textAlign: 'right' }} options={{ style: 'currency', currency: 'GTQ' }} label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em', textAlign: 'center' }}>{"Precio"}</span>} source="price" />
          </Datagrid>
        </List>
      ) : (
        <ThemeProvider theme={theme}>
          <List autoReload={false} empty={false} {...props} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={{}} />} pagination={<PostPagination />} actions={<ListActions create={true} catalog={true} />} bulkActionButtons={false} style={{ marginLeft: "2%" }}>
            <Datagrid  {...props} rowClick="show" style={{ tableLayout: 'fixed', textAlign: 'center' }}  >
              <ImageField src="url" source="url" title="name" label={<span style={Style.dataTitle}>{"Imagen"}</span>} classes={imageFieldClasses} />
              <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="name" />
              <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Descripción"}</span>} source="description" />
              <NumberField textAlign='center' style={Style.TextFieldList} options={{ style: 'currency', currency: 'GTQ' }} label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em', textAlign: 'center' }}>{"Precio"}</span>} source="price" />
            </Datagrid>
          </List>
        </ThemeProvider>
      )}
    </div>
  )
};

export default ProductsList;