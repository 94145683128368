import { useNotify, useRedirect } from 'react-admin';
import { useEffect } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useCallback, useState } from "react";
import { useMediaQuery } from '@material-ui/core';
import { useDataProvider } from "react-admin";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@mui/material/Typography';
import { Style, StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import SimpleDialog from '../Commons/Dialog.jsx';
import ListCustom from '../Commons/ListCustom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [amount, setAmount] = useState(0.00);
    const [country, setCountry] = useState('GT');
    const [countryCode, setCountryCode] = useState('+502');
    const [nit, setNIT] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneflag, setPhoneFlag] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [toBePaid, setToBePaid] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [closeDialog, setCloseDialog] = useState(true);
    const [columnsSearch, setColumnsSearch] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState(null);

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
            typography: {
                fontFamily: 'Gothic',
            },
        },
        esES,
        coreeSES
    );

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleQuantityChange = useCallback((event, row) => {
        let index = rows.findIndex((r) => r.id === row.id);
        let quantity = rows[index].quantity;
        rows[index].quantity = parseFloat(event.target.value);
        let increase = event.target.value - quantity;
        let price = rows[index].price * increase;
        setAmount(amount + price);
        setRows([...rows]);
    }, [rows, setRows, amount, setAmount]);

    useEffect(() => {
        (async () => {
            try {
                setColumnsSearch([
                    {
                        field: 'id',
                        headerName: 'ID',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center'
                    },
                    {
                        field: 'name',
                        headerName: 'Nombre',
                        editable: false,
                        flex: 1,
                        headerAlign: 'center',
                        align: 'center'
                    },
                    {
                        field: 'description',
                        headerName: 'Descripcion',
                        editable: false,
                        flex: 1,
                        headerAlign: 'center',
                        align: 'center'
                    },
                    {
                        field: 'price',
                        headerName: 'Precio',
                        editable: false,
                        flex: 1,
                        headerAlign: 'center',
                        align: 'center'
                    },
                ]);

                setColumns([
                    { field: 'name', headerName: 'Nombre', flex: 1 },
                    { field: 'description', headerName: 'Descripción', flex: 1 },
                    { field: 'price', headerName: 'Precio', flex: 1 },
                    {
                        field: 'quantity',
                        headerName: 'Cantidad',
                        flex: 1,
                        renderCell: (params) => (
                            <input
                                className="InputFieldTable"
                                type="number"
                                min="1"
                                defaultValue={params.value || 1}
                                onChange={(e) => handleQuantityChange(e, params.row)}
                            />
                        ),
                    }
                ]);
            }
            catch {
            }
        })();
    }, [handleQuantityChange]);

    useEffect(() => {
        Upload();
    }, []);

    const Upload = async () => {
        const { data } = await dataProvider.getOne('catalogs/paymentrequest', { id: 0 });
        if (data.detalles != null) {
            let options = data.detalles.map((item) => ({
                value: item.codigo,
                key: item.id
            }));

            setOptions(options);

            if (options.length > 0) {
                const { data: structureinfo } = await dataProvider.getOne('catalogs/details/structure', { id: options[0].key });
                let info = structureinfo.estructuras;
                setNIT(info[1].valor);
                setEmail(info[2].valor);
                setCity(info[3].valor);
                setAddress(info[4].valor);
                setPhone(info[5].valor);
                setOption(options[0].key);
            }
        }
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const handleChangeToBePaid = () => {
        setToBePaid(!toBePaid)
    };

    const handleChangeWhatsapp = () => {
        setWhatsapp(!whatsapp)
    };

    const handleChangeSendEmail = () => {
        setSendEmail(!sendEmail)
    };

    const CreateRequest = async () => {
        if (rows.length === 0) {
            setMessage("No se pudo crear un link de pago sin productos asociados")
            handleClickError();
            return;
        }

        if (!Validate(nit, { nit: true, required: true }) || !Validate(email, { email: true, required: true })
            || !Validate(city, { required: true }) || !Validate(address, { required: true })
            || phoneflag) {
            setMessage("No se pudo crear el link de pago, revise que todos los campos esten ingresados correctamente")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            BillingTaxId: nit,
            BillingEmail: email,
            BillingAddressCity: city,
            BillingAddress: address,
            Detail: rows,
            BillingPhone: phone,
            Country: country,
            CountryCode: countryCode,
            ToBePaid: toBePaid,
            SendEmail: sendEmail,
            Whatsapp: whatsapp
        }

        dataProvider.create('requests', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                redirect('/requests/' + response.data.id);
                notify('Link enviado correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo crear el link de pago");
                handleClickError()
            })
    }

    const handleProductSearch = async (props) => {
        if (!props || props.length === 0) {
            return;
        }

        setSelectedRows(props);

        var products = await dataProvider.getMany('activeproducts/many', { ids: props });
        let updatedProducts = products.data.map(product => ({
            ...product,
            quantity: 1,
        }));

        let amount = updatedProducts.reduce((total, product) => total + product.quantity * product.price, 0);
        setAmount(amount);
        setRows(updatedProducts);
    }

    const HandleCloseDialog = () => {
        setCloseDialog(true);
    }

    let actions = [{ auth: true, action: HandleCloseDialog, name: 'Cerrar', disable: false }]

    let search = <SimpleDialog title="Productos" actions={actions} body={<ListCustom columns={columnsSearch} filter={{ Filter: option }} resource='activeproducts' selection={true} setSelectedRows={handleProductSearch} selectedRows={selectedRows} />} maxWidth='md' close={closeDialog} setClose={setCloseDialog} />;


    const onChangeOptions = async (event) => {
        const { data } = await dataProvider.getOne('catalogs/details/structure', { id: event.target.value });
        let info = data.estructuras;
        setNIT(info[1].valor);
        setEmail(info[2].valor);
        setCity(info[3].valor);
        setAddress(info[4].valor);
        setPhone(info[5].valor);
        setRows([]);
        setSelectedRows([]);
        setAmount(0.00);
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit" style={{ opacity: opacity }}>
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div>
                        <div class="row">
                            <div class="col-4">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={toBePaid}
                                            onChange={handleChangeToBePaid}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography style={Style.dataTitle}>Por Pagar</Typography>}
                                />
                            </div>
                            <div class="col-4">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={sendEmail}
                                            onChange={handleChangeSendEmail}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography style={Style.dataTitle}>Email</Typography>}
                                />
                            </div>
                            <div class="col-4">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={whatsapp}
                                            onChange={handleChangeWhatsapp}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography style={Style.dataTitle}>Whatsapp</Typography>}
                                />
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom value={nit} setValue={setNIT} width="95%" placeholder="Ingresa número de nit" label="NIT *" required name="BillingTaxId" type="text" nit />
                            <InputFieldCustom value={email} setValue={setEmail} col="col-6" width="95%" placeholder="Ingresa correo electrónico de facturación" label="Correo electrónico *" required name="BillingEmail" type="text" email />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={city} setValue={setCity} width="95%" placeholder="Ingresa ciudad de facturación" label="Ciudad *" required name="BillingAddressCity" type="text" />
                            <InputFieldCustom value={address} setValue={setAddress} width="95%" placeholder="Ingresa dirección de facturación" label="Dirección" name="BillingAddress" type="text" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={phone} setValue={setPhone} width="95%" label="Teléfono *" required component="phone" error={phoneflag} setError={setPhoneFlag} setCountry={setCountry} setCountryCode={setCountryCode} />
                            <InputFieldCustom col="col-6" width="100%" component="search" search={search} nolabel />
                        </div>
                        {options.length > 0 &&
                            <div class="row">
                                <InputFieldCustom width='95%' setValue={setOption} select={option} options={options} component="select" label="Empresa" onChange={onChangeOptions} />
                            </div>
                        }
                    </div>
                ) : (
                    <div class="container" style={{ opacity: opacity }}>
                        <div class="row">
                            <InputFieldCustom value={nit} setValue={setNIT} col="col-12" width="100%" placeholder="Ingresa número de nit" label="NIT *" required name="BillingTaxId" type="text" nit />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={email} setValue={setEmail} col="col-12" width="100%" placeholder="Ingresa correo electrónico de facturación" label="Correo electrónico *" required name="BillingEmail" type="text" email />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={phone} setValue={setPhone} col="col-12" width="100%" label="Teléfono *" required component="phone" error={phoneflag} setError={setPhoneFlag} setCountry={setCountry} setCountryCode={setCountryCode} />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={city} setValue={setCity} col="col-12" width="100%" placeholder="Ingresa ciudad de facturación" label="Ciudad *" required name="BillingAddressCity" type="text" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={address} setValue={setAddress} col="col-12" width="100%" placeholder="Ingresa dirección de facturación" label="Dirección" name="BillingAddress" type="text" />
                        </div>
                    </div>)
                }
                <div>
                    <div class="row">
                        <div class="col-12" style={{ textAlign: 'right', marginTop: '1em' }}>
                            <div>Total : Q{amount}</div>
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-12" style={{ height: 400, width: '100%' }}>
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 20, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                    <BottomToolBar buttons={[
                        { auth: true, action: CreateRequest, name: 'Link', disable: disable }
                    ]} />
                    <div class="row" style={{ textAlign: 'center' }}>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostCreate