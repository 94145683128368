import { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputFieldCustom from '../../Commons/InputFieldCustom'
import { StyleTheme, DataGridStyle } from '../../Commons/Styles.jsx';
import { useMediaQuery, createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../../Commons/BottomToolBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import historyTracker from '../../Commons/historyTracker';
import BreadCrumbCustom from '../../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StructureEdit = (props) => {
    const [name, setName] = useState('');
    const [columns, setColumns] = useState([]);
    const [openerror, setOpenerror] = useState(false);
    const [details, setDetails] = useState([]);
    const [opensnack, setOpensnack] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const dataProvider = useDataProvider();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const history = useHistory();

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const Update = async () => {

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        dataProvider.update('catalogs/details', { id: props.id, data: details })
            .then(response => {
                handleClick()
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClickError()
            })
    }

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const EditCell = useCallback((event, params) => {
        details[params.id].value = event.target.value;
        setDetails([...details]);
    }, [details, setDetails]);

    useEffect(async () => {
        setColumns([
            {
                field: 'code',
                headerName: 'Codigo',
                headerAlign: 'center',
                align: 'center',
                editable: false,
                flex: 1
            },
            {
                field: 'value',
                headerName: 'Valor',
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => {
                    return (
                        <input
                            className="InputFieldTable"
                            defaultValue={params.value}
                            onChange={(e) => EditCell(e, params)}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.stopPropagation();
                                }

                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.target.blur();
                                }
                            }}
                        />
                    )
                },
            }
        ]);

    }, [EditCell]);

    useEffect(() => {
        Upload();
    }, []);

    const Upload = async () => {
        const { data } = await dataProvider.getOne('catalogs/details', { id: props.id });
        historyTracker.addPath(history.location.pathname, data.detail.codigo, 2);
        let values = data.detail.estructuras.map((item, index) => ({
            id: index,
            code: item.codigo,
            value: item.valor,
            key: item.id
        }));

        setName(data.detail.codigo);
        setDetails(values);
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit" style={{ opacity: opacity }}>
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div>
                        <div class="row">
                            <InputFieldCustom value={name} readOnly={true} setValue={setName} col="col-6" width="95%" label="Nombre" required type="text" />
                        </div>
                        <div class="row" style={{ width: '100%' }}>
                            <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={details}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                            disableRowSelectionOnClick
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </div>
                        <div class="row">
                            <div>
                                <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                    <div>
                                        <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                            La información del catálogo ha sido actualizada
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            La información del catálogo no se ha podido actualizar
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div class="row">
                            <InputFieldCustom value={name} setValue={setName} col="col-12" width="100%" placeholder="Ingresa nombre de catálogo" label="Nombre de catálogo" required />
                        </div>
                        <div class="row">
                            <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={details}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            disableMultipleSelection={true}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </div>
                        <div class="row">
                            <div>
                                <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                    <div>
                                        <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                            La información del catálogo ha sido actualizada
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            La información del catálogo no se ha podido actualizar
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                )}
                <BottomToolBar buttons={[
                    { auth: true, action: Update, name: 'Guardar', disable: disable }
                ]} />
            </div >
        </div>
    )
};

export default StructureEdit;