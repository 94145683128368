import {useDataProvider} from 'react-admin';
import { useEffect, useState } from "react";

const CalendarShow = (props) => {
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    useEffect(() => {
        (async () => {
            try {
                const { data } = await dataProvider.getOne('activecalendars', { id: props.id });
                setState(data);
            }
            catch {
            }
        })();
    }, [dataProvider, props]);

    return (
        <div className="App">
            <iframe title={state.calendly} src={state.calendly} source="calendly" width="100%" height="400%"></iframe>
        </div>
    )
};

export default CalendarShow;