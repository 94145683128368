import { Filter, useListContext } from 'react-admin';
import { useState, useEffect } from "react";
import { Card, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    overrides: {
        MuiMenuItem: {
            root: {
                fontFamily: 'Gothic',
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: 'white',
                border: '2px solid #ccc',
                fontFamily: 'Gothic',
                fontWeight: 'bold',
                fontSize: '0.85em',
                height: '2.6rem'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'white',
                borderRadius: '7px',
                borderTopRightRadius: '7px',
                borderTopLeftRadius: '7px',
            },
            underline: {
                '&:before': {
                    borderBottom: 'none',
                    borderRadius: '7px',
                },
                '&:after': {
                    borderBottom: 'none',
                    borderRadius: '7px',
                },
            },
        },
        RaFilterFormInput: {
            body: {
                width: '100%',
                marginTop: '0.1rem'
            }
        },
        MuiFormControl: {
            marginDense: {
                minWidth: '100%',
            }
        },
        RaSelectInput: {
            input: {
                minWidth: '100%'
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'Gothic',
                fontWeight: 'bold',
                fontSize: '0.85em',
                color: '#191268',
                width: '100%'
            }
        }
    },
});

const FilterSidebar = ({ props, filters, defaultvalues }) => {

    const { setFilters, } = useListContext();
    useEffect(() => {
        (async () => {
            try {
                setFilters(defaultvalues);
            }
            catch {
            }
        })();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ order: -1, mr: 2, mt: 0, width: 250 }}>
                <CardContent>
                    <Filter {...props} filterValues={defaultvalues}>
                        {
                            filters.map((item) => (
                                item
                            ))
                        }
                    </Filter>
                </CardContent>
            </Card>
        </ThemeProvider>)
};

export default FilterSidebar;