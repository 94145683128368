import { useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useQRCode } from 'react-hook-qrcode';
import link from '../Commons/Icons/Copiar-12.svg';
import download from '../Commons/Icons/Descargar QR-21.svg';
import InputFieldCustom from '../Commons/InputFieldCustom'
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useEffect, useState, useCallback } from "react";
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileButton from "../Commons/FileButton";
import BottomToolBar from '../Commons/BottomToolBar';
import { StyleTheme, DataGridStyle } from '../Commons/Styles.jsx'
import { createStyles, makeStyles } from '@material-ui/core';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const urlproduct = process.env.REACT_APP_URL ? process.env.REACT_APP_URL + 'product/' : '';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const bloburl = process.env.REACT_APP_URL_BLOB ?? '';

const ProductEdit = (props) => {
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    var isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    var myRef = React.createRef();
    const dataProvider = useDataProvider();
    const [stateChecked, setStateChecked] = useState(null);
    const [columns, setColumns] = useState([]);
    const [opensnack, setOpensnack] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [additional, setAdditional] = useState('');
    const [sku, setSKU] = useState('');
    const [disponibility, setDisponibility] = useState('');
    const [limit, setLimit] = useState('');
    const [price, setPrice] = useState('');
    const [id, setId] = useState('');
    const [details, setDetails] = useState([]);
    const [image, setImage] = useState(undefined);
    const [imageVersion, setImageVersion] = useState("");
    const [url, setUrl] = useState({});
    const [catalogs, setCatalogs] = useState([]);
    const [catalogsinfo, setCatalogsInfo] = useState({});
    const [type, setType] = useState('');
    const [calendly, setCalendly] = useState('');
    const [view, setView] = useState('00000000-0000-0000-0000-000000000000');
    const [views, setViews] = useState([]);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [info, setInfo] = useState({});

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const history = useHistory()

    const Delete = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        dataProvider.delete('products', { id: id })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/products');
            })
            .catch(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                alert("No se ha podido eliminar el producto");
            });
    }

    const Update = async () => {
        if (!Validate(name, { product: true, required: true }) || !Validate(description, { required: true }) ||
            !Validate(sku, { required: true }) || !Validate(limit, { number: true, required: true }) ||
            !Validate(price, { money: true, required: true }) || !Validate(disponibility, { number: true, required: true })) {
            handleClickError();
            return;
        }

        var data = {
            prices: details.map((item, index) => ({
                price: parseFloat(item.price),
                installment: parseInt(item.installment),
                id: item.key,
            })),
            name,
            description,
            price,
            limit,
            disponibility,
            catalogsinfo,
            image,
            additionalInfo: additional,
            sku,
            calendly,
            type,
            view
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        dataProvider.update('products', { id: id, data: data })
            .then(response => {
                if (response.data.imageVersion !== imageVersion) {
                    setImageVersion(response.data.imageVersion);
                }

                //setCatalogs(response.data.catalogs);
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClick();

            })
            .catch((error) => {
                console.log(error)
                handleClickError();
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
    }

    const onChangeSelect = (props) => {
        catalogsinfo[props.target.name] = props.target.value;
        setCatalogsInfo(catalogsinfo);
    }

    const EditCell = useCallback((event, params, field) => {
        details[params.id][field] = event.target.value;
        setDetails([...details]);
    }, [details, setDetails]);

    useEffect(() => {
        let columns = [
            {
                field: 'installment',
                headerName: 'Cuota',
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                editable: false
            },
            {
                field: 'price',
                headerName: 'Precio',
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => {
                    return (
                        <input
                            className="InputFieldTable"
                            defaultValue={params.value}
                            onChange={(e) => EditCell(e, params, 'price')}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.stopPropagation();
                                }

                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.target.blur();
                                }
                            }}
                        />
                    )
                },
            }
        ];

        setColumns(columns);
    }, [EditCell])

    useEffect(() => {
        Upload();
    }, []);

    const Upload = async () => {
        try {
            let { data } = await dataProvider.getOne(props.resource, { id: props.id });
            historyTracker.addPath(history.location.pathname, data.id, 1);
            for (var i in data.catalogs) {
                data.catalogs[i].detalles.unshift({ value: "No aplica", key: "00000000-0000-0000-0000-000000000000" })
            }

            data.views.unshift({ value: "No aplica", key: "00000000-0000-0000-0000-000000000000" })


            var infodetails = data.prices?.map((item, index) => ({
                id: index,
                key: item.id,
                installment: item.installment,
                price: item.price
            }));


            if (data.active !== undefined) {
                setStateChecked(data.active);
            }

            setId(props.id)
            setDetails(infodetails);
            setName(data.name);
            setDescription(data.description)
            setAdditional(data.additionalInfo);
            setSKU(data.sku);
            setDisponibility(data.disponibilidad)
            setLimit(data.limit)
            setPrice(parseFloat(data.price).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }))
            setCatalogs(data.catalogs);
            setImageVersion(data.imageVersion);
            setUrl(data.url);
            setType(data.type);
            setCalendly(data.calendly)
            setView(data.view);
            setViews(data.views)
            setInfo(data);
        }
        catch {
        }
    }

    const handleChange = (event) => {
        dataProvider.update('product/available', { id: props.id }).then(response => {
            setStateChecked(!stateChecked);
        }).catch((error) => { })
    };

    const Download = () => {
        const pngUrl = myRef.current
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
    }

    const QR = ({ url, width }) => {
        const [inputRef] = useQRCode({
            text: url,
            options: {
                level: 'M',
                margin: 7,
                scale: 1,
                width: width,
                color: {
                    dark: '#2A3353',
                    light: '#ffffff',
                },
            },
        });

        myRef = inputRef;

        return (
            <div class="col-12">
                <div class="row">
                    <span style={{ fontFamily: 'Gothic', marginLeft: '2em' }}>Código QR</span>
                </div>
                <div class="row">
                    <div class="col-12">
                        <canvas id="qr" ref={inputRef} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <Button
                            color="primary"
                            style={{ marginLeft: '3em', width: "7em", color: 'white', height: '2.5em', fontFamily: 'Gothic', textTransform: 'none' }}
                            onClick={Download}>
                            <img src={download} alt="logo" style={{ maxWidth: '9rem', maxHeight: '2.5rem' }} />
                        </Button>
                    </div>
                </div>
            </div>);
    };
    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit">
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div style={{ opacity: opacity }}>
                        <div class="row">
                            <div class="col-7">
                                <div style={{ marginTop: '0em', marginLeft: '0%' }}>
                                    <FormControlLabel
                                        style={{ fontFamily: 'Gothic' }}
                                        control={
                                            <Switch
                                                checked={stateChecked}
                                                onChange={handleChange}
                                                name="checkedFact"
                                                color="primary"
                                            />
                                        }
                                        label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Disponible"}</span>}
                                    />
                                </div>
                                <div class="row">
                                    <InputFieldCustom value={name} setValue={setName} col="col-12" width="100%" placeholder="Ingresa nombre del producto (max 50 caracteres)" label="Nombre del Producto *" required product />
                                </div>
                                <div class="row">
                                    <InputFieldCustom value={description} setValue={setDescription} col="col-12" width="100%" placeholder="Ingresa descripción del producto" label="Descripción del Producto *" required component="textarea" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom value={additional} setValue={setAdditional} col="col-12" width="100%" placeholder="Ingresa información adicional del Producto" label="Información Adicional" component="textarea" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom value={sku} setValue={setSKU} col="col-4" width="100%" placeholder="Ingresa SKU del producto" label="SKU*" required />
                                    {
                                        type !== "S" ? (
                                            <InputFieldCustom value={disponibility} setValue={setDisponibility} col="col-4" width="100%" placeholder="Ingresa disponibilidad" label="Disponibilidad *" required number />
                                        ) : null
                                    }
                                    <InputFieldCustom value={limit} setValue={setLimit} col="col-4" width="100%" placeholder="Ingresa límite por transacción" label="Límite *" required number />
                                </div>
                                <div class="row">
                                    <InputFieldCustom value={price} setValue={setPrice} col="col-4" width="100%" placeholder="Ingresa el precio del producto" label="Precio (Q) *" required money />
                                    <InputFieldCustom col="col-4" width="100%" label="Tipo" value={type === "S" ? "Servicio" : "Bien"} readOnly={true} />
                                    <InputFieldCustom col="col-4" width="100%" label="Unidad" value={info.unit === "S" ? "Servicio" : info.unit === "U" ? "Unidad" : "Libra"} readOnly={true} />
                                </div>
                                <div class="row">
                                    <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                        <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                            <ThemeProvider theme={theme}>
                                                <DataGrid
                                                    rows={details}
                                                    columns={columns}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 10,
                                                            },
                                                        },
                                                    }}
                                                    checkboxSelection={false}
                                                    disableMultipleSelection={true}
                                                    pageSizeOptions={[10, 20, 50, 100]}
                                                />
                                            </ThemeProvider>
                                        </Box>
                                    </div>
                                </div>
                                {type === "S" ? (
                                    <div class="row">
                                        <InputFieldCustom value={calendly} setValue={setCalendly} col="col-12" width="100%" label="Link a Calendly" />
                                    </div>
                                ) : null}
                                {views && views.length !== 0 ? (
                                    <div class="row">
                                        <InputFieldCustom setValue={setView} options={views} value={view} component="select" width="100%" col="col-12" label="Vista" />
                                    </div>
                                ) : null}
                                {stateChecked ? (
                                    <div>
                                        <div class="row">
                                            <InputFieldCustom value={url} col="col-12" width="100%" label="Link de Pago" readOnly />
                                        </div>
                                        <div class="row">
                                            <div class="col-4"></div>
                                            <div class="col-8">
                                                <div style={{ width: '100%', textAlign: 'right', }}>
                                                    <Button color="primary" style={{ width: "6.3rem", height: '2.5rem', marginTop: '2.5em' }}
                                                        icon={<noicon />} onClick={() => { navigator.clipboard.writeText(urlproduct + props.id) }}>
                                                        <img src={link} alt="logo" style={{ maxWidth: '9rem', maxHeight: '2.5rem', marginTop: '0%' }} /></Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <QR url={urlproduct + props.id} width={200}></QR>
                                        </div>
                                    </div>
                                ) : (
                                    <div class="col-12">
                                        <label style={{ fontFamily: 'BoldGothic', fontWeight: 1000, color: '#1c1855', fontSize: '0.85em', minWidth: "100%", borderColor: "white" }}>{"Producto no se encuentra disponible"}</label>
                                    </div>
                                )}
                                {catalogs ? catalogs.map((element) =>
                                (
                                    <div class="row">
                                        <InputFieldCustom options={element.detalles} value={element.selected} col='col-12' width='100%' onChange={onChangeSelect} setValue={() => { }} label={element.value} catalogId={element.id} name={element.value + "Filter" + element.idRel} component="select" />
                                    </div>
                                ))
                                    :
                                    null}
                                <div class="row" style={{ textAlign: 'center', marginBottom: '1em', marginTop: '2em' }}>
                                    <div>
                                        <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                            <div>
                                                <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                                    La información del producto se ha actualizado
                                                </Alert>
                                            </div>
                                        </Snackbar>
                                    </div>
                                    <div>
                                        <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                            <div>
                                                <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                                    La información del producto no se ha podido actualizar
                                                </Alert>
                                            </div>
                                        </Snackbar>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-12" style={{ marginTop: '0em', textAlign: 'center', position: 'relative' }}>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <img src={bloburl + imageVersion + ".png?e=" + imageVersion} alt="" className={classes.image} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <FileButton setValue={setImage} id="productimage" image={null} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="container" style={{ width: '100%', opacity: opacity }}>
                        <div class="row">
                            <div class="col-12" style={{ paddingTop: '0.5em' }}>
                                <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '0.1em' }}>Datos del Producto</span>
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom readOnly={info.sales} value={name} width="100%" setValue={setName} col="col-12" placeholder="Ingresa nombre del producto (max 50 caracteres)" label="Nombre del Producto *" required product />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={description} setValue={setDescription} col="col-12" width="100%" placeholder="Ingresa descripción del producto" label="Descripción del Producto *" required component="textarea" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={additional} setValue={setAdditional} col="col-12" width="100%" placeholder="Ingrese información adicional del producto" label="Información Adicional" component="textarea" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={sku} setValue={setSKU} col="col-12" width="100%" placeholder="Ingresa SKU del producto" label="SKU" required />
                        </div>
                        {type !== "S" ? (
                            <div class="row">
                                <InputFieldCustom value={disponibility} setValue={setDisponibility} col="col-12" width="100%" placeholder="Ingresa disponibilidad" label="Disponibilidad *" number />
                            </div>) : null}
                        <div class="row">
                            <InputFieldCustom value={limit} setValue={setLimit} col="col-12" width="100%" placeholder="Ingresa límite por transacción" label="Límite *" number />
                        </div>
                        <div class="row">
                            <InputFieldCustom col="col-12" width="100%" label="Tipo" value={type === "S" ? "Servicio" : "Bien"} readOnly={true} />
                        </div>
                        <div class="row">
                            <InputFieldCustom col="col-12" width="100%" label="Unidad" value={info.unit === "S" ? "Servicio" : info.unit === "U" ? "Unidad" : "Libra"} readOnly={true} />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={price} setValue={setPrice} col="col-12" width="100%" placeholder="Ingresa el precio del producto" label="Precio (Q) *" required money />
                        </div>
                        <div class="row">
                            <div clas="col-12" style={{ width: '100%', marginLeft: '1rem' }}>
                                <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={details}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            checkboxSelection={false}
                                            disableMultipleSelection={true}
                                            pageSizeOptions={[10, 20, 50, 100]}
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </div>
                        {type === "S" ? (
                            <div class="row">
                                <InputFieldCustom value={calendly} setValue={setCalendly} col="col-12" width="100%" label="Link a Calendly" />
                            </div>
                        ) : null}
                        <div class="row">
                            <InputFieldCustom value={url} col="col-12" width="100%" label="Link de Pago" required readOnly />
                        </div>
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6">
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <Button color="primary" style={{ width: "6.3rem", height: '2.5rem', marginTop: '2.5em' }}
                                        icon={<noicon />} onClick={() => { navigator.clipboard.writeText(urlproduct + props.id) }}>
                                        <img src={link} alt="logo" style={{ maxWidth: '7rem', maxHeight: '2.5rem' }} /></Button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style={{ paddingTop: '0.5em' }}>
                                <span style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '0.1em' }}>Imagen del Producto</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style={{ marginTop: '0em', textAlign: 'center', height: '20em', width: '85%', position: 'relative' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <img src={bloburl + imageVersion + ".png?e=" + imageVersion} alt="" className={classes.img} />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style={{ marginTop: '0em', textAlign: 'center' }}>
                                <FileButton title="" setValue={setImage} id="productimage" image={null} heightimg={'100%'} widthimg={"100%"} />
                            </div>
                        </div>
                        {catalogs ? catalogs.map((element) =>
                        (
                            <div class="row">
                                <InputFieldCustom col={"col-12"} width="100%" onChange={onChangeSelect} setValue={() => { }} options={element.detalles} value={element.selected} label={element.value} catalogId={element.id} name={element.value + "Filter" + element.idRel} component="select" />
                            </div>
                        ))
                            :
                            null}

                        <div>
                            <div>
                                <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                    <div>
                                        <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                            La información del producto se ha actualizado
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                            <div>
                                <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                    <div>
                                        <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                            La información del producto no se ha podido actualizar
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                )}
                <BottomToolBar buttons={[
                    { auth: true, action: Update, name: 'Guardar', disable: disable },
                    { auth: true, action: Delete, name: 'Eliminar', disable: disable }
                ]} />
            </div>
        </div>
    )
};

export default ProductEdit;