import { Layout, MenuItemLink } from 'react-admin';
import MyAppBar from './MyAppBar'
import products from './Components/Commons/Icons/noun_bag_3747465.svg';
import invoices from './Components/Commons/Icons/noun_invoice_3492253.svg';
import reports from './Components/Commons/Icons/noun_growth_3785236.svg';
import bag from './Components/Commons/Icons/noun_Money_3751165.svg';
import trans from './Components/Commons/Icons/noun_transaction_2182047.svg';
import shop from './Components/Commons/Img/Logo.png';
import shopicon from './Components/Commons/Img/icono.png';
import { useSelector } from 'react-redux';
import MySidebar from './MySidebar';
import { useAuth0 } from "@auth0/auth0-react";
import logoutsvg from './Components/Commons/Icons/noun_logout_2035127.svg';
import calendar from './Components/Commons/Icons/noun_monthly calendar_1589933.svg';
import catalog from './Components/Commons/Icons/noun-catalog-352895.svg';

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    active: {
        borderLeft: '3px solid #4f3cc9'
    }
}));


const menuItems = [
    { name: 'requests', text: 'Transacciones', icon: <img src={trans} alt="logo" style={{ maxWidth: '1.3em', maxHeight: '1.3em' }} /> },
    { name: 'clearings', text: 'Liquidaciones', icon: <img src={bag} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> },
    { name: 'products', text: 'Productos', icon: <img src={products} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> },
    { name: 'invoice', text: 'Facturas', icon: <img src={invoices} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> },
    { name: 'reports', text: 'Reportes', icon: <img src={reports} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> },
    { name: 'activecalendars', text: 'Calendarios', icon: <img src={calendar} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> },
    { name: 'catalogs', text: 'Catálogos', icon: <img src={catalog} alt="logo" style={{ maxWidth: '1.5em', maxHeight: '1.5em' }} /> }
];

const MyMenu = ({ onMenuClick }) => {
    const { logout } = useAuth0();
    const classes = useStyles();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    let iconmenu = open ? shop : shopicon;
    let maxHeight = open ? '11em' : '2.5em';
    let maxWidth = open ? '13em' : '2.5em';
    let paddingLeftmax = open ? '1.4em' : '1em';

    const onClick = async () => {
        logout();
    }

    return (
        <div>
            <img src={iconmenu} alt="logo" style={{ maxWidth: maxWidth, maxHeight: maxHeight, marginTop: '1em', paddingLeft: paddingLeftmax }} />
            {menuItems.map(item => (
                <MenuItemLink
                    classes={{ active: classes.active }}
                    dense={true}
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                    style={{
                        color: '#262150',
                        fontWeight: 'bold',
                        fontSize: '1em',
                        marginTop: '2em',
                        fontFamily: ['Gothic'
                        ].join(','),
                    }}
                />
            ))}
            <MenuItemLink
                dense={true}
                key="Cerrar Sesión"
                to={`/#`}
                primaryText="Cerrar Sesión"
                leftIcon={<img src={logoutsvg} alt="logo" style={{ maxWidth: '2.2em', maxHeight: '1.5em' }} />}
                onClick={onClick}
                style={{
                    color: '#262150',
                    fontWeight: 'bold',
                    fontSize: '1em',
                    marginTop: '2em',
                    fontFamily: ['Gothic'
                    ].join(','),
                }}
            />
        </div>)
};



const MyLayout = props => <Layout {...props} menu={MyMenu} appBar={MyAppBar} sidebar={MySidebar} />;

export default MyLayout;