import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, makeStyles, ThemeProvider, createTheme } from '@material-ui/core';
import { TextInput, Datagrid, ImageField, TextField, List } from 'react-admin';
import FilterSidebar from '../Commons/FilterSidebar';
import { Style } from '../Commons/Styles.jsx';

let h = 75;
let w = 75;

const useImageFieldStyles = makeStyles(theme => ({
  image: {
    width: w,
    height: h,
    objectFit: 'contain',
  }
}));

const theme = createTheme({
  overrides: {
    MuiCard: {
      root: {
        width: '90%',
      },
    }
  }
})

const CalendarList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const imageFieldClasses = useImageFieldStyles();

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"Apellido"}</span>} source="q" alwaysOn />
  ];

  return !isSmall ? (
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ThemeProvider theme={theme}>
            <List {...props} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={{}} />} actions={<ListActions calendar={true} />} pagination={<PostPagination />} bulkActionButtons={false}>
              <Datagrid  {...props} rowClick="show" style={{ tableLayout: 'fixed', textAlign: 'center' }}  >
                <ImageField src="url" source="url" title="name" label={<span style={Style.dataTitle}>{"Imagen"}</span>} classes={imageFieldClasses} />
                <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="name" />
              </Datagrid>
            </List>
          </ThemeProvider>
        </div>
      </div>
    </div>
  ) : (
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ThemeProvider theme={theme}>
            <List {...props} actions={<ListActions calendar={true} />} pagination={<PostPagination />} bulkActionButtons={false}>
              <Datagrid  {...props} rowClick="show" style={{ tableLayout: 'fixed', textAlign: 'center' }}  >
                <ImageField src="url" source="url" title="name" label={<span style={Style.dataTitle}>{"Imagen"}</span>} classes={imageFieldClasses} />
                <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="name" />
              </Datagrid>
            </List>
          </ThemeProvider>
        </div>
      </div>
    </div>
  )
};

export default CalendarList;