import StateTextField from '../Commons/StateTextField'
import { useDataProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import {StyleTheme, DataGridStyle } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import { esES as coreeSES } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ClearingShow = (props) => {
    var isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const history = useHistory();
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const theme = createTheme(
        DataGridStyle,
        esES,
        coreeSES,
    );

    useEffect(() => {
        (async () => {
            try {
                const { data } = await dataProvider.getOne('clearings', { id: props.id });
                historyTracker.addPath(history.location.pathname, data.data.id, 1);
                let info = data.data;
                state.total = info.totalIngresos;
                state.transactionsAccount = info.cantidadTransacciones;
                state.transactionsReceived = info.transferenciasRecibidas;
                state.messages = info.mensajesEnviados;
                state.feemessages = info.costoMensajesEnviados;
                state.feeignoredlinks = info.costoLinksIgnorados;
                state.feeinvoices = info.costoFacturas;
                state.invoices = info.facturasEmitidas;
                state.feeCyberSource = info.costoCybersource;
                state.amountReceivable = info.saldoMontoPorCobrar;
                state.accountpayable = info.deduccionCxC;
                state.feeRerversedLinks = info.costoLinksReversados;
                state.comission = info.comision;
                state.amountToSettled = info.montoALiquidar;
                state.state = !info.liquidado ? 'Pendiente' : 'Liquidado';
                state.color = !info.liquidado ? '#2A3353' : '#01BF8F';

                setState(state);

                var transactionsinfo = data.transactions.map(function (item, index) {
                    return {
                        id: index,
                        name: item.clearing.paymentRequest.authorizationRequest.billingForename + ' ' + item.clearing.paymentRequest.authorizationRequest.billingSurname,
                        amount: item.clearing.paymentRequest.authorizationRequest.amount,
                        description: item.clearing.paymentRequest.authorizationRequest.productLines[0].description,
                    }
                });

                setRows(transactionsinfo);
                setColumns([
                    {
                        field: 'name',
                        headerName: 'Cliente',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'description',
                        headerName: 'Producto',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'amount',
                        headerName: 'Precio (Q)',
                        flex: 1,
                        editable: false,
                        headerAlign: 'center',
                        align: 'center',
                    }
                ]);
            }
            catch (error) {
            }
        })();
    }, []);

    return (
        <div class="container"  >
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {!isSmall ? (
                <div class="edit">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2">
                                    <span style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em' }}> Estado:</span>
                                </div>
                                <div class="col-2" style={{ textAlign: 'center' }}>
                                    <StateTextField state={state.state} color={state.color} fontsize="0.85em" width='8em' height="2em" paddingTop="3%" />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div class="row">
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.total} label="Total Ingresos" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.transactionsAccount} label="Cantidad de Transacciones" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.transactionsReceived} label="Dinero de transferencias" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.messages} label="Link Enviados por mensajes" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.feemessages} label="Costo de Link Enviados por mensajes" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.feeignoredlinks} label="Costo Links Ignorados" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.feeinvoices} label="Costo facturas" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.invoices} label="Cantidad de facturas emitidas" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.feeCyberSource} label="Costo CyberSource" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.amountReceivable} label="Saldo Monto Por Pagar" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.accountpayable} label="Abono a Cuenta por Pagar" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.feeRerversedLinks} label="Costo cobros reversados" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.comission} label="% Shoppeira" />
                        <InputFieldCustom col="col-4" width='100%' setValue={() => { }} value={state.amountToSettled} label="Monto a Liquidar" />
                    </div>
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>

                </div>
            ) : (
                <div class="edit">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-4">
                                    <span style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em' }}> Estado: </span>
                                </div>
                                <div class="col-6" style={{ textAlign: 'center', marginTop: '0.3em', marginLeft: '0em' }}>
                                    <StateTextField state={state.state} color={state.color} fontsize="0.85em" />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.total} label="Total Ingresos" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.transactionsAccount} label="Cantidad de Transacciones" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.transactionsReceived} label="Dinero de transferencias" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.messages} label="Link Enviados por mensajes" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.feemessages} label="Costo de Link Enviados por mensajes" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.feeignoredlinks} label="Costo Links Ignorados" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.feeinvoices} label="Costo facturas" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.invoices} label="Cantidad de facturas emitidas" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.feeCyberSource} label="Costo CyberSource" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.amountReceivable} label="Saldo Monto Por Pagar" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.accountpayable} label="Abono a Cuenta por Pagar" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.feeRerversedLinks} label="Costo cobros reversados" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.comission} label="% Shoppeira" />
                    </div>
                    <div class="row">
                        <InputFieldCustom col="col-12" width='100%' setValue={() => { }} value={state.amountToSettled} label="Monto a Liquidar" />
                    </div>
                    <Box sx={{ height: 400, width: '100%' }} style={{ marginTop: '1.2em' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        </ThemeProvider>
                    </Box>

                </div>
            )}
        </div>
    );
}

export default ClearingShow