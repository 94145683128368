import { Style } from '../Commons/Styles.jsx';
import { useRef, useState, useEffect } from "react";
import Validate from '../Commons/Validate';
import { useMediaQuery } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input'
import { createStyles, makeStyles } from '@material-ui/core';
import { parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const InputFieldCustom = (props) => {
    const {
        type, placeholder, readOnly, width, value, setValue, setCountry, setCountryCode, required,
        money, nit, number, component, noinput, options, date, onChange, search, error, setError, name, nolabel
    } = props;
    const timer = useRef(null);
    const [color, setColor] = useState('#EFEFEF');
    let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const StyleTheme = {
        phone: {
            "& input.PhoneInputInput": {
                fontFamily: 'Gothic',
                border: '2px solid',
                borderRadius: '7px',
                fontWeight: 'bold',
                width: '100%',
                fontSize: '1em',
                height: '2.7em',
                paddingInlineStart: '1em',
                borderColor: color,
            },
        }
    };

    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            try {
                if (error)
                    setColor('red');
                else
                    setColor('#EFEFEF');

                if(nit && value !== undefined){
                    let newValue = value.replace(/-/g, '');
                    setValue(newValue);
                }
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, [error, value]);

    const onChangeDefaultSelect = (props) => {
        let valueinfo = props.target.value;
        setValue(valueinfo);
        if (onChange) {
            onChange(props);
        }
    }

    const onChangeDefault = (props) => {
        let valueinfo = props.target.value;
        if (nit) {
            let newValue = valueinfo.replace(/-/g, '');
            setValue(newValue);
        } else {
            setValue(valueinfo);
        }

        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if (money) {
                let decimals = (valueinfo.indexOf('.') !== -1) ? (valueinfo.length - valueinfo.indexOf('.') - 1) : 0;
                let valuemoney = valueinfo.replace(/[^0-9.-]/g, '');
                if (isNaN(valuemoney) || valuemoney === '') {
                    setValue('0.00');
                    return;
                }

                let money = decimals > 2 ? parseFloat(valuemoney) * (Math.pow(10, (decimals - 2))) : parseFloat(valuemoney);
                if (money > 9999999999999) {
                    let newprice = parseFloat(value.replace(/[^0-9.-]/g, ''));
                    setValue(newprice.toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                    return;
                }

                setValue(money.toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }));

            }

            if (!Validate(props.target.value, { required: required, money: money, date: date, nit: nit, number: number })) {
                setColor('red')
            } else {
                setColor('#EFEFEF');
                if (setError)
                    setError(false);
            }

            if (onChange) {
                onChange(props);
            }

        }, 500);

    };

    const onChangeDefaultPhone = (props) => {
        try {
            var countryval = parsePhoneNumber(props);
            if (countryval && setCountryCode && setCountry) {
                setCountryCode("+" + countryval.countryCallingCode);
                setCountry(countryval.country);
            }
        } catch {
            setColor('red');
            setError(true);
        }

        if (props === undefined || props.length === 0) {
            setColor('red');
            setError(true);
        } else {
            setColor('#EFEFEF')
            setError(false);
        }

        setValue(props);
    };

    let centermobile = isSmall ? { textAlign: 'center' } : {};

    function renderComponent(component) {
        switch (component) {
            case 'textarea':
                return (
                    <div class="row">
                        <div class="col-12" style={centermobile}>
                            <textarea className="InputField" type={type} onChange={(event) => onChangeDefault(event)} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%', minHeight: '5em' }} readOnly={readOnly} value={value} />
                        </div>
                    </div>
                );
            case 'select':
                return (
                    <div class="row">
                        <div class="col-12" style={centermobile}>
                            <select disabled={readOnly} name={name} onChange={(e) => { onChangeDefaultSelect(e) }} style={{ ...Style.SelectCustom, width: width }} readOnly={readOnly}>
                                {
                                    options.map((item) => (
                                        <option value={item.key} selected={value === item.key}>{item.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                );
            case 'search':
                return (
                    <div class="row">
                        {noinput &&
                            <div class="col-9">
                                <input className="InputField" type={type} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%' }} readOnly={readOnly} value={value} />
                            </div>
                        }
                        <div class={noinput ? "col-3" : "col-12"} style={{ paddingTop: nolabel ? '2.6em' : '0em' }}>
                            {search}
                        </div>
                    </div>
                );
            case 'phone':
                return (
                    <div class="row">
                        <div class="col-12" style={centermobile}>
                            <PhoneInput
                                style={Style.phone}
                                defaultCountry="GT"
                                placeholder="Ingresa número de teléfono"
                                label="Número telefónico"
                                required
                                name="Phone"
                                value={value}
                                onChange={(event) => onChangeDefaultPhone(event)}
                                className={classes.phone} />
                        </div>
                    </div>
                );

            case 'input':
            default:
                return (
                    <div class="row">
                        <div class="col-12" style={centermobile}>
                            <input className={isSmall ? 'InputFieldMobile' : 'InputField'} type={type} onChange={(event) => onChangeDefault(event)} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%' }} readOnly={readOnly} value={value} />
                        </div>
                    </div>
                );
        }
    }


    return (
        <div class={props.col ? props.col : "col-6"}>
            {!nolabel &&
                <div class="row" style={{ paddingTop: '1.2em' }}>
                    <div class="col-12">
                        <label style={Style.InputFieldLabel}>{props.label}</label>
                    </div>
                </div>
            }
            {renderComponent(component)}
        </div >
    );
};

export default InputFieldCustom;