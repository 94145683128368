import Button from '@material-ui/core/Button';
import { Style } from './Styles.jsx';
import { ChromePicker } from 'react-color';

const ColorButton = (props) => {
    const { title, color, click, open, onChange, onClose } = props;
    return (
        <div class="col-4">
            <div class="row" style={Style.CenterFlex}>
                <label style={Style.LabelColor}>{title}</label>
            </div>
            <div class="row" style={Style.PositionColor}>
                <div style={Style.PositionButtonColor}>
                    <Button style={{ ...Style.PositionButtonColor2, backgroundColor: color }} onClick={click}>Elegir</Button>
                    {open ? <div style={Style.popover}>
                        <div style={Style.cover} onClick={onClose} />
                        <ChromePicker color={color} onChangeComplete={onChange} />
                    </div> : null}
                </div>
            </div>
        </div>
    );
};

export default ColorButton;