import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import bag from '../Commons/Icons/noun_cart_2035418.svg';
import { Chart } from "react-google-charts";
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useQuery, Loading, Error } from 'react-admin';

let dataInfo = [];
let dataMean = [];
let dataProd = [];
let dataPopulares = [];
let wth = "100%";

const useStyles = makeStyles({
  root: {
    minWidth: '50%',
    marginTop: '1.75em'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const ReportList = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'reports',
    payload: { id: "reports" }
  });

  if (loading) return <Loading loadingPrimary="Cargando" loadingSecondary="Página está cargando. Espere un segundo" />;
  if (error) return <Error />;
  if (!data) return null;

  var total = 0;
  var totalProd = 0;
  var months = 0;
  var monthsA = data.monthsA;
  var analyzed = [];
  var monthWord = "ene";

  for (var i of monthsA) {
    var dateC = i.month.toString().split("-");
    var date = dateC[0];
    switch (date) {
      case "1":
        monthWord = "ene"
        break;
      case "2":
        monthWord = "feb"
        break;
      case "3":
        monthWord = "mar"
        break;
      case "4":
        monthWord = "abr"
        break;
      case "5":
        monthWord = "may"
        break;
      case "6":
        monthWord = "jun"
        break;
      case "7":
        monthWord = "jul"
        break;
      case "8":
        monthWord = "ago"
        break;
      case "9":
        monthWord = "sep"
        break;
      case "10":
        monthWord = "oct"
        break;
      case "11":
        monthWord = "nov"
        break;
      case "12":
        monthWord = "dic"
        break;
      default:
        break;
    }

    analyzed.push([monthWord, i.count])
    total = total + i.count;
    months++;
  }

  data["reportMonth"] = analyzed;

  var monthsM = data.meanMonth;
  var analyzedMean = [];

  for (var info of monthsM) {
    var dateC2 = info.month.toString().split("-");
    var date2 = dateC2[0];
    switch (date2) {
      case "1":
        monthWord = "ene"
        break;
      case "2":
        monthWord = "feb"
        break;
      case "3":
        monthWord = "mar"
        break;
      case "4":
        monthWord = "abr"
        break;
      case "5":
        monthWord = "may"
        break;
      case "6":
        monthWord = "jun"
        break;
      case "7":
        monthWord = "jul"
        break;
      case "8":
        monthWord = "ago"
        break;
      case "9":
        monthWord = "sep"
        break;
      case "10":
        monthWord = "oct"
        break;
      case "11":
        monthWord = "nov"
        break;
      case "12":
        monthWord = "dic"
        break;
      default:
        break;
    }

    analyzedMean.push([monthWord, info.count])
  }

  data["reportMean"] = analyzedMean;

  var monthsP = data.prods;
  var analyzedProd = [];

  for (var info2 of monthsP) {
    var dateC3 = info2.month.toString().split("-");
    var date3 = dateC3[0];

    switch (date3) {
      case "1":
        monthWord = "ene"
        break;
      case "2":
        monthWord = "feb"
        break;
      case "3":
        monthWord = "mar"
        break;
      case "4":
        monthWord = "abr"
        break;
      case "5":
        monthWord = "may"
        break;
      case "6":
        monthWord = "jun"
        break;
      case "7":
        monthWord = "jul"
        break;
      case "8":
        monthWord = "ago"
        break;
      case "9":
        monthWord = "sep"
        break;
      case "10":
        monthWord = "oct"
        break;
      case "11":
        monthWord = "nov"
        break;
      case "12":
        monthWord = "dic"
        break;
      default:
        break;
    }

    analyzedProd.push([monthWord, i.count])
    totalProd = totalProd + i.count;
  }

  var monthsPopular = data.prodsVendidos;
  var analyzedPopular = [];
  for (var popular of monthsPopular) {
    var d = [popular.product.toString(), popular.count]
    analyzedPopular.push(d);
  }

  data["reportProd"] = analyzedProd;
  data["reportPopular"] = analyzedPopular;

  dataInfo = data.reportMonth;
  if (dataInfo.length === 0) {
    dataInfo.push(["0", 0]);
  }

  dataMean = data.reportMean;
  if (dataMean.length === 0) {
    dataMean.push(["0", 0]);
  }

  dataProd = data.reportProd;
  if (dataProd.length === 0) {
    dataProd.push(["0", 0]);
  }

  dataPopulares = data.reportPopular;

  var num = total / months;
  var num2 = totalProd / months;
  data["meanTotal"] = num.toFixed(2);
  data["meanVentas"] = num2.toFixed(0);

  if (!isSmall) {
    wth = "100%"
  } else {
    wth = "350px"
  }

  return !isSmall ? (
    <div class="container">
      <div class="row">
        <div class="col-4">
        </div>
        <div class="col-4">
          <Card className={classes.root}>
            <CardContent>
              <div>
                <div class="row">
                  <div class="col-4">
                    <div style={{ background: '#5300E3', borderRadius: '60%', width: '4em', minHeight: '4em' }}>
                      <img src={bag} alt="logo" style={{ filter: 'invert(100%) sepia(1%) saturate(2438%) hue-rotate(211deg) brightness(106%) contrast(93%)', maxWidth: '3.5em', maxHeight: '3.5em', paddingTop: '0.7em', paddingLeft: '0.5em', fill: 'white' }} />
                    </div>
                  </div>
                  <div class="col-md-8" style={{ textAlign: 'right' }}>
                    <span class="pull-right" style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.4em' }}> Ingresos Promedio Mensual</span >
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" style={{ textAlign: 'right' }}>
                    <span class="pull-right" style={{ fontFamily: 'Gothic', color: '#2A335', fontWeight: 'bold', fontSize: '2em' }}>Q{data.meanTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div class="col-6">
        </div>
      </div>
      <div class="row" style={{ marginTop: '1em' }}>
        <div class="col-6">
          <Chart
            width={wth}
            height={300}
            chartType="ColumnChart"
            loader={<div>Cargando gráfico</div>}
            data={[

              ['City', 'Ingresos'],
              ...dataInfo
            ]}
            options={{
              title: 'Ingresos mensuales',
              chartArea: { width: '70%' },
              fontName: 'Gothic',
              hAxis: {
                title: 'Mes',
                minValue: 0,
              },
              vAxis: {
                title: 'Ingresos totales',
              },
            }}
            legendToggle
          />
        </div>
        <div class="col-6">
          <Chart
            width={wth}
            height={300}
            chartType="ColumnChart"
            loader={<div>Cargando gráfico</div>}
            data={[

              ['City', 'Ingresos'],
              ...dataMean
            ]}
            options={{
              title: 'Ticket promedio por mes',
              fontName: 'Gothic',
              chartArea: { width: '70%' },
              hAxis: {
                title: 'Mes',
                minValue: 0,
              },
              vAxis: {
                title: 'Ingresos promedio por venta',
              },
            }}
            legendToggle
          />
        </div>
      </div>
      <div class="row" style={{ marginTop: '1em' }}>
        <div class="col-6">
          <Chart
            width={wth}
            height={'300px'}
            chartType="PieChart"
            loader={<div>Cargando gráfico</div>}
            data={[
              ['Producto', 'Ventas'],
              ...dataPopulares
            ]}
            options={{
              title: 'Productos más vendidos',
              is3D: true,
              fontName: 'Gothic',
              chartArea: { width: '70%' }
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div class="container" style={{ display: 'flex', flexDirection: "column", maxWidth: 400 }}>
      <div class="row">
        <div class="col-12">
          <Card className={classes.root}>
            <CardContent>
              <div class="row">
                <div class="col-4">
                  <div style={{ background: '#5300E3', borderRadius: '60%', width: '4em', height: '4em' }}>
                    <img src={bag} alt="logo" style={{ filter: 'invert(100%) sepia(1%) saturate(2438%) hue-rotate(211deg) brightness(106%) contrast(93%)', maxWidth: '3.5em', maxHeight: '3.5em', paddingTop: '0.7em', paddingLeft: '0.5em', fill: 'white' }} />
                  </div>
                </div>
                <div class="col-md-8" style={{ textAlign: 'right' }}>
                  <span class="pull-right" style={{ fontFamily: 'Gothic', color: '#230856', fontWeight: 'bold', fontSize: '1.4em' }}> Ventas Promedio Mensual</span >
                </div>
              </div>
              <div class="row">
                <div class="col-12" style={{ textAlign: 'right' }}>
                  <span class="pull-right" style={{ fontFamily: 'Gothic', color: '#2A335', fontWeight: 'bold', fontSize: '2em' }}>Q{data.meanTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div class="row" style={{ marginTop: '1em', display: 'flex', flexDirection: "column" }}>
        <div class="col-12">
          <Chart
            width={wth}
            height={300}
            chartType="ColumnChart"
            loader={<div>Cargando gráfico</div>}
            data={[

              ['City', 'Ingresos'],
              ...dataInfo
            ]}
            options={{
              title: 'Ingresos mensuales',
              chartArea: { width: '70%' },
              fontName: 'Gothic',
              hAxis: {
                title: 'Mes',
                minValue: 0,
              },
              vAxis: {
                title: 'Ingresos totales',
              },
            }}
            legendToggle
          />
        </div>
      </div>
      <div class="row" style={{ marginTop: '1em', display: 'flex', flexDirection: "column" }}>
        <div class="col-12">
          <Chart
            width={wth}
            height={300}
            chartType="ColumnChart"
            loader={<div>Cargando gráfico</div>}
            data={[

              ['City', 'Ingresos'],
              ...dataMean
            ]}
            options={{
              title: 'Ticket promedio por mes',
              fontName: 'Gothic',
              chartArea: { width: '70%' },
              hAxis: {
                title: 'Mes',
                minValue: 0,
              },
              vAxis: {
                title: 'Ingresos promedio por venta',
              },
            }}
            legendToggle
          />
        </div>
      </div>
      <div class="row" style={{ marginTop: '1em', display: 'flex', flexDirection: "column" }}>
        <div class="col-12">
          <Chart
            width={wth}
            height={'300px'}
            chartType="PieChart"
            loader={<div>Cargando gráfico</div>}
            data={[
              ['Producto', 'Ventas'],
              ...dataPopulares
            ]}
            options={{
              title: 'Productos más vendidos',
              is3D: true,
              fontName: 'Gothic',
              chartArea: { width: '70%' }
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      </div>
    </div>
  )
};

export default ReportList;