import { Admin, Resource, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import withValidations from "./withValidations";
import { Route } from 'react-router-dom';
import MyLayout from './MyLayout';
import { createTheme } from '@material-ui/core';

import RequestList from './Components/Requests/RequestList';
import ReportList from './Components/Reports/ReportList';
import CalendarList from './Components/Calendar/CalendarList';
import CatalogList from './Components/Catalogs/CatalogList';
import CatalogsCreate from './Components/Catalogs/PostCreate';
import CalendarShow from './Components/Calendar/CalendarShow';
import RequestCreate from './Components/Requests/PostCreate';
import RequestShow from './Components/Requests/RequestShow';

import InvoiceList from './Components/Invoice/InvoiceList';
import InvoiceCreate from './Components/Invoice/PostCreate';
import InvoiceShow from './Components/Invoice/InvoiceShow';

import ProductCreate from './Components/Products/ProductCreate'
import ProductList from './Components/Products/ProductsList';
import ProductEdit from './Components/Products/ProductEdit';

import ClearingList from './Components/Clearing/ClearingList'
import ClearingShow from './Components/Clearing/ClearingShow';

import Profile from './Components/Profile/Profile';
import CatalogEdit from "./Components/Catalogs/CatalogEdit";
import StructureEdit from './Components/Catalogs/Structure/StructureEdit';

const routes = [
  <Route exact path="/profile" component={Profile} />
];

const domain = process.env.REACT_APP_URL_API ?? "";

const App = () => {

  const { getIdTokenClaims } = useAuth0();

  const fetchJson = async (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const customHeaders = await (async () => {
      var c = await getIdTokenClaims();
      return {
        user: c?.sub,
      }
    })();

    for (var header in customHeaders) {
      options.headers.set(header, customHeaders[header]);
    }

    return fetchUtils.fetchJson(url, options);
  }

  const dataProvider = simpleRestProvider(domain, fetchJson)

  const myDataProvider = {
    ...dataProvider,
    create: async (resource, params) => {
      if (resource !== 'products') {
        return dataProvider.create(resource, params);
      }

      var price = params.data.price.toString();

      return dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          price: price
        },
      })
    },
    update: async (resource, params) => {
      // return dataProvider.create(resource, params);
      if (resource !== 'products') {
        // fallback to the default implementation
        return dataProvider.update(resource, params);
      }

      var price = params.data.price.toString();

      return dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          price: price
        },
      })
    },

    post: async (resource, params) => {
      const { data } = params;
      var c = await getIdTokenClaims();
      const url = `${domain}/${resource}`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          user: c?.sub,
          username: c?.nickname
        },
        body: JSON.stringify(data),
      };

      return fetch(url, options)
        .then(async response => {
          if (!response.ok) {
            const error = await response.text();
            console.log(error);
            throw new Error(JSON.parse(error));
          }
          return response.json();
        })
        .then(json => ({
          data: json,
        }));
    }
  };

  const theme = createTheme();

  return (
    <Admin appLayout={MyLayout} dataProvider={myDataProvider} customRoutes={routes} theme={theme}>
      <Resource name="requests" list={RequestList} create={RequestCreate} show={RequestShow} />
      <Resource name="clearings" list={ClearingList} edit={ClearingShow} />
      <Resource name="products" list={ProductList} create={ProductCreate} edit={ProductEdit} />
      <Resource name="invoice" list={InvoiceList} create={InvoiceCreate} show={InvoiceShow} />
      <Resource name="reports" list={ReportList} />
      <Resource name="activecalendars" list={CalendarList} show={CalendarShow} />
      <Resource name="catalogs" list={CatalogList} create={CatalogsCreate} edit={CatalogEdit} />
      <Resource name="structures" show={StructureEdit} />
      <Resource name="profile" />
    </Admin>
  )
}

export default withAuthenticationRequired(withValidations(App));