import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { useMediaQuery, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import React from 'react';
import InputFieldCustom from '../Commons/InputFieldCustom'
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Style, StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import FileButton from "../Commons/FileButton";
import ColorButton from '../Commons/ColorButton';

const google = window.google;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Profile = (props) => {
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const { getIdTokenClaims } = useAuth0();
    const [name, setName] = useState('');
    const [establishment, setEstablishment] = useState(1);
    const [regime, setRegime] = useState('');
    const [IVA, setIVA] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [policies, setPolicies] = useState('');
    const [timeZone, setTimeZone] = useState(null);
    const [NIT, setNIT] = useState('');
    const [businessName, setbusinessName] = useState('');
    const [bank, setBank] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [accountNumber, setAccountNumber] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [address, setAddress] = useState('');
    const [state, setState] = useState(null);
    const [states, setStates] = useState([]);
    const [town, setTown] = useState(null);
    const [towns, setTowns] = useState([]);
    const [logoProduct, setLogoProduct] = useState("");
    const [logoSale, setLogoSale] = useState("");
    const [openColorTitle, setOpenColorTitle] = useState(false);
    const [openColorButton, setOpenColorButton] = useState(false);
    const [openColorTotal, setOpenColorTotal] = useState(false);
    const [logoProductImage, setLogoProductImage] = useState(null);
    const [logoSaleImage, setLogoSaleImage] = useState(null);
    const [colorTitle, setColorTitle] = useState('#2CCCE4');
    const [colorButton, setColorButton] = useState('#2CCCE4');
    const [colorTotal, setColorTotal] = useState('#2CCCE4');
    const [timeZones, setTimeZones] = useState([]);
    const [open, setOpen] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [id, setId] = useState(null);
    const [phoneflag, setPhoneFlag] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const dataProvider = useDataProvider();
    var isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const IVAs = [
        { 'key': 'GEN', 'value': 'General' },
        { 'key': 'EXE', 'value': 'Exento' },
        { 'key': 'PEQ', 'value': 'Pequeño Contribuyente' }
    ];

    const Regimens = [
        { 'key': 11, 'value': 'ISR Sobre Utilidades' },
        { 'key': 12, 'value': 'ISR Opcional Simplificado' },
        { 'key': 13, 'value': 'ISR Opcional Simplificado Actividades' },
        { 'key': 31, 'value': 'No Genera Crédito Fiscal' },
        { 'key': 46, 'value': 'Servicios Exentos' },
        { 'key': 48, 'value': 'Servicios Exentos Educativos Privados' },
        { 'key': 416, 'value': 'Ventas Servicios Universidades' }
    ];

    const Banks = [
        { 'key': 'Banco Industrial', 'value': 'Banco Industrial' },
        { 'key': 'Banrural', 'value': 'Banrural' },
        { 'key': 'G&T', 'value': 'G&T' }
    ];

    const AccountTypes = [
        { 'key': 'Monetaria', 'value': 'Monetaria' },
        { 'key': 'Ahorro', 'value': 'Ahorro' }
    ];

    const Currencies = [
        { 'key': 'GTQ', 'value': 'Quetzales' },
        { 'key': 'USD', 'value': 'Dólares' }
    ];

    const Update = async () => {

        if (!Validate(businessName, { required: true }) || !Validate(establishment, { number: true, required: true }) ||
            !Validate(email, { required: true }) || phoneflag ||
            !Validate(NIT, { nit: true, required: true }) || !Validate(accountNumber, { required: true })
            || !Validate(address, { required: true })) {
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var data = {
            businessName,
            affiliation: IVA,
            regime: parseInt(regime),
            email,
            establishment: parseInt(establishment),
            taxId: NIT,
            phone,
            timeZone,
            policies,
            bank,
            accountNumber,
            accountType,
            currency,
            address,
            latitude,
            longitude,
            townId: town,
            colorTitle,
            colorButton,
            colorTotal,
            logoProduct,
            logoSale,
            logoProductImage,
            logoSaleImage
        }

        dataProvider.update('profile', { id: id, data: data })
            .then(response => {
                setLogoProduct(response.data.logoProduct);
                setLogoSale(response.data.logoSale);
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClick();
            })
            .catch(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                handleClickError();
            })
    }

    const onChangeSelectStates = async (props) => {
        var data = await dataProvider.getOne('towns', { id: props.target.value });
        setTowns(data.data.towns);
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const handleChangeColorTitle = (color) => {
        setColorTitle(color.hex);
    }

    const OpenColorTitle = () => {
        setOpenColorTitle(!openColorTitle);
    }

    const handleCloseColorTitle = () => {
        setOpenColorTitle(false);
    };

    const handleChangeColorButton = (color) => {
        setColorButton(color.hex);
    }

    const OpenColorButton = () => {
        setOpenColorButton(!openColorButton);
    }

    const handleCloseColorButton = () => {
        setOpenColorButton(false);
    };

    const handleChangeColorTotal = (color) => {
        setColorTotal(color.hex);
    }

    const OpenColorTotal = () => {
        setOpenColorTotal(!openColorTotal);
    }

    const handleCloseColorTotal = () => {
        setOpenColorTotal(false);
    };

    useEffect(() => {
        (async () => {
            try {
                var claims = await getIdTokenClaims();
                const { data } = await dataProvider.getOne('profile', { id: claims.sub });
                setId(data.id);
                setbusinessName(data.businessName);
                setIVA(data.affiliation);
                setRegime(data.regime);
                setEmail(data.email);
                setTimeZone(data.timeZone);
                setTimeZones(data.timeZones);
                setPolicies(data.policies);
                setPhone(data.phone);
                setNIT(data.taxId);
                setName(data.taxName);
                setBank(data.bank);
                setCurrency(data.currency);
                setAccountType(data.accountType);
                setAccountNumber(data.accountNumber);
                setAddress(data.address);
                setLatitude(data.latitude);
                setLongitude(data.longitude);
                setState(data.stateHeader);
                setStates(data.states);
                setTown(data.townId);
                setTowns(data.towns);
                setColorTitle(data.colorTitle);
                setColorButton(data.colorButton);
                setColorTotal(data.colorTotal);
                setLogoProduct(data.logoProduct);
                setLogoSale(data.logoSale);
                setEstablishment(data.establishment)
            }
            catch {
            }
        })();
    }, [dataProvider, getIdTokenClaims]);

    if (!state) return null;

    class MapContainer extends React.Component {

        state = {
            currentLocation: this.props,
            markers: [],
            bounds: null
        };


        onMapLoad = map => {
            navigator?.geolocation.getCurrentPosition(
                ({ coords: { latitude: lat, longitude: lng } }) => {
                    const pos = { lat, lng };
                    let current = {}
                    if (longitude && latitude) {
                        current = { lat: latitude, lng: longitude };
                    }
                    else {
                        current = pos;
                    }

                    this.setState({ currentLocation: current });
                }
            );

            google.maps.event.addListener(map, "bounds_changed", () => {
                this.setState({ bounds: map.getBounds() });
            });
        };

        onSBLoad = ref => {
            this.searchBox = ref;
        };

        onPlacesChanged = () => {
            let markerArray = [];
            let results = this.searchBox.getPlaces();
            for (let i = 0; i < results.length; i++) {
                let place = results[i].geometry.location;
                markerArray.push(place);
            }
            this.setState({ markers: markerArray });
            try {
                var current = { lat: this.state.markers[0].lat(), lng: this.state.markers[0].lng() };
                this.setState({ currentLocation: current });
                setLatitude(current.lat);
                setLongitude(current.lng);
            } catch (e) {
            }
        }

        onMarkerDragEnd = (coord, index) => {
            const { latLng } = coord;
            const lat = latLng.lat();
            const lng = latLng.lng();
            setLatitude(lat);
            setLongitude(lng);

            this.setState(prevState => {
                const markers = [...this.state.markers];
                markers[index] = {
                    ...markers[index],
                    position: { lat, lng }
                };
                return { markers };
            });
        }

        render() {
            return (
                <GoogleMap
                    center={this.state.currentLocation}
                    zoom={16}
                    onLoad={map => this.onMapLoad(map)}
                    mapContainerStyle={{ height: "200px", width: "100%" }}
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                    }}
                >
                    <div id="searchbox">
                        <StandaloneSearchBox
                            onLoad={this.onSBLoad}
                            onPlacesChanged={this.onPlacesChanged}
                            bounds={this.state.bounds}
                        >
                            <input

                                placeholder="Búsqueda"
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: this.props.small ? "18rem" : '25rem',
                                    height: `32px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    marginTop: `18px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: this.props.small ? "76%" : "60%",
                                    marginLeft: "-16em",
                                    fontFamily: 'Gothic'
                                }}
                            />
                        </StandaloneSearchBox>
                    </div>
                    {this.state.markers.length === 0 ?
                        <Marker
                            position={{ lat: this.props.lat, lng: this.props.lng }}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                        /> :
                        this.state.markers.map((mark, index) => (
                            <Marker
                                key={index}
                                position={mark}
                                draggable={true}
                                onDragEnd={this.onMarkerDragEnd} />
                        ))}
                </GoogleMap>
            );
        }
    }

    return !isSmall ? (
        <div class="container" style={{ opacity: opacity }}>
            <div class="Loading" style={{ display: displayLoading }}>
                <CircularProgress size="15rem" />
            </div>
            <label style={Style.Label}>Información de la Cuenta</label>
            <div class="edit">
                <div class="container">
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setbusinessName} value={businessName} placeholder="Nombre" label="Nombre de la Cuenta *" required />
                        <InputFieldCustom width='95%' setValue={setEstablishment} value={establishment} label="Establecimiento *" required number />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setIVA} select={IVA} options={IVAs} component="select" label="Afiliación IVA" />
                        <InputFieldCustom width='95%' setValue={setRegime} select={regime} options={Regimens} component="select" label="Régimen" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setEmail} value={email} placeholder="Correo Electrónico" label="Correo Electrónico *" required />
                        <InputFieldCustom width='95%' setValue={setTimeZone} options={timeZones} select={timeZone} component="select" label="Zona Horaria" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setPolicies} placeholder="Ingresa políticas de devolución" label="Políticas de devolución" component="textarea" value={policies} />
                        <InputFieldCustom value={phone} setValue={setPhone} width="100%" label="Teléfono *" required component="phone" error={phoneflag} setError={setPhoneFlag} />
                    </div>
                </div>
            </div>
            <label style={Style.Label}>Información de la Tienda</label>
            <div class="edit">
                <div class="container" >
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setNIT} value={NIT} placeholder="NIT" label="NIT *" required nit />
                        <InputFieldCustom width='95%' value={name} label="Nombre de la Empresa" readOnly={true} />
                    </div>
                </div>
            </div>
            <label style={Style.Label}>Información de Pago</label>
            <div class="edit">
                <div class="container" >
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setBank} options={Banks} component="select" select={bank} label="Banco" />
                        <InputFieldCustom width='95%' setValue={setAccountType} options={AccountTypes} component="select" select={accountType} label="Tipo de Cuenta" />
                    </div>
                    <div class="row">
                        <InputFieldCustom width='95%' setValue={setCurrency} options={Currencies} component="select" select={currency} label="Moneda" />
                        <InputFieldCustom width='95%' setValue={setAccountNumber} value={accountNumber} label="Número de Cuenta" />
                    </div>
                </div>
            </div>
            <label style={Style.Label}>Ubicación</label>
            <div class="edit">
                <div class="container"  >
                    <div class="row">
                        <div class="col-6" style={{ marginTop: '1em' }}>
                            <div class="row">
                                <div class="col-12">
                                    <label style={Style.Map}>Ubicación</label>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '-0.5em' }} >
                                <div class="col-12">
                                    <MapContainer lat={latitude} lng={longitude} />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <InputFieldCustom width='95%' setValue={setAddress} col="col-12" value={address} label="Dirección" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-12" width='95%' setValue={setState} onChange={onChangeSelectStates} options={states} component="select" select={state} label="Departamento" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-12" width='95%' setValue={setTown} options={towns} component="select" select={town} label="Municipio" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label style={Style.Label}>Colores</label>
            <div class="edit">
                <div class="container"  >
                    <div class="row" style={{ marginTop: '2em' }}>
                        <ColorButton title="Títulos" color={colorTitle} click={OpenColorTitle} open={openColorTitle} onChange={handleChangeColorTitle} onClose={handleCloseColorTitle} />
                        <ColorButton title="Botones" color={colorButton} click={OpenColorButton} open={openColorButton} onChange={handleChangeColorButton} onClose={handleCloseColorButton} />
                        <ColorButton title="Total" color={colorTotal} click={OpenColorTotal} open={openColorTotal} onChange={handleChangeColorTotal} onClose={handleCloseColorTotal} />
                        <div style={{ height: '17em' }}></div>
                    </div>
                </div>
            </div>
            <label style={Style.Label}>Logos</label>
            <div class="edit">
                <div class="container">
                    <div class='row' style={{ marginTop: '2em' }}>
                        <FileButton image={logoProduct} title="Logo Producto" setValue={setLogoProductImage} id="productimage" />
                        <FileButton image={logoSale} title="Logo Ventas" setValue={setLogoSaleImage} id="saleimage" />
                    </div>
                </div>
            </div>
            <BottomToolBar buttons={[{ auth: true, action: Update, name: 'Guardar', disabled: disable }]} />
            <div>
                <div>
                    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <div>
                            <Alert onClose={handleClose} severity="success" className={classes.myAlert} variant="outlined" >
                                La información de su perfil ha sido actualizada
                            </Alert>
                        </div>
                    </Snackbar>
                </div>
                <div>
                    <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                        <div>
                            <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                La información de su perfil no se ha podido actualizar
                            </Alert>
                        </div>
                    </Snackbar>
                </div>
            </div>
        </div >
    ) : (
        <div className="edit" style={{ opacity: opacity }}>
            <div class="LoadingMobile" style={{ display: displayLoading }}>
                <CircularProgress size="15rem" />
            </div>
            <label style={Style.LabelMobile}>Información de la Cuenta</label>
            <div class="containerMobile">
                <div class="row">
                    <InputFieldCustom setValue={setbusinessName} col="col-12" width='95%' value={businessName} label="Nombre de la Cuenta" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setEstablishment} col="col-12" width='95%' value={establishment} label="Establecimiento *" required number />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setIVA} col="col-12" width='95%' options={IVAs} select={IVA} label="Afiliación IVA" component="select" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setRegime} col="col-12" width='95%' options={Regimens} value={regime} label="Régimen" component="select" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setEmail} col="col-12" width='95%' value={email} label="Correo Electrónico" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setTimeZone} col="col-12" width='95%' options={timeZones} component="select" select={timeZone} label="Zona Horaria" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setPolicies} col="col-12" width='95%' placeholder="Ingresa políticas de devolución" component="textarea" value={policies} label="Políticas de devolución" />
                </div>
                <div class="row">
                    <InputFieldCustom value={phone} setValue={setPhone} col="col-12" width="100%" label="Teléfono *" required component="phone" error={phoneflag} setError={setPhoneFlag} />
                </div>
            </div>
            <label style={Style.LabelMobile}>Información de la Tienda</label>
            <div class="containerMobile">
                <div class="row">
                    <InputFieldCustom setValue={setNIT} col="col-12" width="95%" value={NIT} label="NIT" />
                </div>
                <div class="row">
                    <InputFieldCustom col="col-12" width="95%" value={name} label="Nombre de la Empresa" readOnly={true} />
                </div>
            </div>
            <label style={Style.LabelMobile}>Información de Pago</label>
            <div class="containerMobile">
                <div class="row">
                    <InputFieldCustom setValue={setBank} col="col-12" width='95%' options={Banks} component="select" select={bank} label="Banco" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setAccountType} col="col-12" width='95%' options={AccountTypes} component="select" select={accountType} label="Tipo de Cuenta" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setCurrency} col="col-12" width='95%' options={Currencies} component="select" select={currency} label="Moneda" />
                </div>
                <div class="row">
                    <InputFieldCustom setValue={setAccountNumber} col="col-12" width='95%' value={accountNumber} label="Número de Cuenta" />
                </div>
            </div>
            <BottomToolBar buttons={[{ auth: true, action: Update, name: 'Guardar', disabled: disable }]} />
            <div>
                <div>
                    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <div>
                            <Alert onClose={handleClose} severity="success" className={classes.myAlert} variant="outlined" >
                                La información de su perfil ha sido actualizada
                            </Alert>
                        </div>
                    </Snackbar>
                </div>
                <div>
                    <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                        <div>
                            <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                La información de su perfil no se ha podido actualizar
                            </Alert>
                        </div>
                    </Snackbar>
                </div>
            </div>
        </div>
    )
}

export default Profile;