function SelectCustom(props) {
    const {onChange} = props;
    let width = props.width ? props.width : '100%'

    return (
        <div class={props.col ?? "col-6"}>
            <div class="row" style={{ marginTop: '1.2em' }}>
                <div class="col-12">
                    <label style={{ fontFamily: 'Gothic', fontWeight: 'bold', minWidth: '25em', fontSize: '0.85em', height: '2em', paddingLeft: '1em' }}>{props.label}</label>
                </div>
            </div>
            <div class="row" style={{ marginTop: '-0.9em' }} >
                <div class="col-12">
                    <select name={props.name} onChange={(e) => { onChange(e) }} style={{ fontFamily: 'Gothic', border: '2px solid #EFEFEF', borderRadius: '7px', fontWeight: 'bold', width: width, fontSize: '0.85em', height: '2.7em', paddingInlineStart: '1em', marginTop: '0em' }}>
                        {
                            props.options.map((item) => (
                                <option value={item.key} selected={props.select === item.key}>{item.value}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </div>
    );
}

export default SelectCustom;