import { List, DateField, NumberField, TextInput } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import TextFieldGrid from '../Commons/TextFieldGrid';
import Url from '../Commons/Url';
import ListActions from '../Commons/ListActions';
import { StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import { useMediaQuery } from '@material-ui/core';
import { useLayoutEffect, useState, useEffect } from "react";
import historyTracker from '../Commons/historyTracker';
import { useLocation } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';

function useMediaQueryWidth() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
}

const InvoiceList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const [width] = useMediaQueryWidth();
  const [historyInfo, setHistoryInfo] = useState(historyTracker.getHistory());
  const location = useLocation();
  var hiddenurl = width < 1220;
  var hiddenauth = width < 1350;

  useEffect(() => {
    (async () => {
      try {
        historyTracker.cleanHistory();
        historyTracker.addPath(location.pathname, 'Facturas', 0);
        setHistoryInfo(historyTracker.getHistory());
      }
      catch {
      }
    })();
  }, []);

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"NIT"}</span>} source="nit" alwaysOn />
  ];

  return (
    <div class="container">
      <BreadCrumbCustom history={historyInfo} />
      {isSmall ? (
        <List empty={false} {...props} pagination={<PostPagination />} bulkActionButtons={false} actions={<ListActions download={true} create={true} />}>
          <Datagrid {...props} rowClick="show" optimized style={{ textAlign: 'center' }}>
            <TextFieldGrid source="taxId" label="NIT" sortable={false} cellClassName={classes.fields} />
            <NumberField source="amount" label="Total" sortable={false} options={{ style: 'currency', currency: 'GTQ' }} cellClassName={classes.fields} style={{ color: 'black', fontFamily: 'Gothic' }} />
          </Datagrid>
        </List>
      ) : (
        <List empty={false} {...props} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={{}} />} pagination={<PostPagination />} bulkActionButtons={false} actions={<ListActions download={true} create={true} />}>
          <Datagrid {...props} rowClick="show" optimized style={{ textAlign: 'center' }}>
            <TextFieldGrid source="taxId" label="NIT" sortable={false} cellClassName={classes.fields} />
            <TextFieldGrid source="name" label="Nombre" sortable={false} cellClassName={classes.fields} />
            <NumberField source="amount" label="Total" options={{ style: 'currency', currency: 'GTQ' }} sortable={false} cellClassName={classes.fields} style={{ color: 'black', fontFamily: 'Gothic' }} />
            {hiddenauth ? null :
              <TextFieldGrid source="authId" label="Autorizacion" sortable={false} cellClassName={classes.fields} />
            }
            {hiddenurl ? null :
              <Url source="fileUrl" label="Factura" sortable={false} cellClassName={classes.fields} style={{ color: '#1c1855', fontFamily: 'Gothic' }} />
            }
            {hiddenurl ? null :
              <DateField source="created" style={{ color: 'black', fontFamily: 'Gothic' }} label="Creado" showTime={true} sortable={false} cellClassName={classes.fields} />
            }
          </Datagrid>
        </List>
      )}
    </div>
  )
};

export default InvoiceList;