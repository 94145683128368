import { useState } from 'react';
import { Style, StyleTheme } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import edit from '../Commons/Icons/edit.svg';

const FileButton = (props) => {

    const { title, image, setValue, id, heightimg, widthimg } = props;
    const bloburl = process.env.REACT_APP_URL_BLOB ?? '';
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const [newImage, setNewImage] = useState(null);

    let col = image ? 'col-2' : 'col-8';

    const handleFileChange = async (event) => {
        setValue(await convertFileToBase64(event.target.files[0]));
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setNewImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            setNewImage(null);
        }
    };

    const convertFileToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });

    return (
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <label style={Style.LabelLogo}>{title}</label>
                    </div>
                    <div class="row">
                        {image &&
                            <div class="col-6">
                                <div style={{ width: '98%', height: '100%' }}>
                                    <img src={bloburl + image + ".png"} alt="" className={classes.image} />
                                </div>
                            </div>
                        }
                        {newImage && (
                            <div class={col}>
                                <img src={newImage} alt="Preview" style={{ width: widthimg || '5em', height: heightimg || '5em' }} />
                            </div>
                        )}
                        <div class={"col-2"}>
                            <input type="file" id={id} style={{ display: 'none' }} onChange={handleFileChange} />
                            <label htmlFor={id} style={{ padding: '9%', backgroundColor: '#191268', color: 'white', cursor: 'pointer', borderRadius: '100%' }}>
                                <img src={edit} alt="logo" style={{ width: '1.4em', height: '1.2em' }} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileButton;
