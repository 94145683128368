import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { http } from "./https";

const domain = process.env.REACT_APP_URL_API ?? "";

const withValidations = (Component) => (props) => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const [com, setCom] = useState(<span>Espere...</span>);


    useEffect(() => {
        (async () => {
            try {
                var claims = await getIdTokenClaims();
                if (!isAuthenticated) {
                    setCom(<div style={{}}><span>Error no estas autenticado</span></div>);
                    return;
                }

                if (!claims?.email_verified) {
                    setCom(<div className="centered"><div className="child"><span>Antes de continuar verifica tu email.</span></div></div>);
                    return;
                }

                try {
                    http(new Request(`${domain}/auth0/${claims.sub}`, {
                        method: 'GET',
                    }));
                }
                catch {
                    setCom(<div className="centered"><div className="child"><span>Estamos pasando por problemas, intente en un momento.</span></div></div>);
                    return;
                }

                setCom(<Component {...props} />);
            } catch (e) {
                //setHasError(true);
            }
        })();
    }, [getIdTokenClaims, isAuthenticated, props]);
    return com;
};

export default withValidations