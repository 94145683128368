import { useDataProvider, useRedirect, useNotify } from 'react-admin';
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useState, useEffect } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useAuth0 } from "@auth0/auth0-react";
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyleTheme } from '../Commons/Styles.jsx';
import { useMediaQuery, createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import historyTracker from '../Commons/historyTracker';
import BreadCrumbCustom from '../Commons/BreadCrum';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [father, setFather] = useState(null);
    const [structure, setStructure] = useState(null);
    const [name, setName] = useState('');
    const [filter, setFilter] = useState(false);
    const [isStructure, setIsStructure] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [fathers, setFathers] = useState([]);
    const [structures, setStructures] = useState([]);
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const { getIdTokenClaims } = useAuth0();
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                var claims = await getIdTokenClaims();
                const { data } = await dataProvider.getOne('parentcatalogs', { id: claims.sub });
                for (var father of data.catalogs) {
                    fathers.push({ key: father.id, value: father.nombre })
                }

                setFathers(fathers);

                const { data: structuresinfo } = await dataProvider.getOne('catalogs/structures', { id: claims.sub });

                for (var structure of structuresinfo.catalogs) {
                    structures.push({ key: structure.id, value: structure.nombre })
                }

                setStructures(structures);
            }
            catch {
            }
        })();
    }, [dataProvider, getIdTokenClaims, fathers, structures]);


    const CreateRequest = async () => {

        if (!Validate(name, true, { required: true })) {
            setMessage("No se puedo crear el catálogo, revise que todos los campos esten ingresados correctamente")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            name,
            catalogoId: father,
            filter,
            isStructure,
            structureId: structure
        }

        dataProvider.create('catalogs', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                redirect('/catalogs/' + response.data.id + '/show');
                notify('Catálogo creado correctamente');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo crear el catálogo");
                handleClickError();
            })
    }

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            <div class="edit" style={{ opacity: opacity }}>
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="LoadingMobile" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div>
                        <div class="row">
                            <div class="col-2">
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    control={
                                        <Switch
                                            checked={filter}
                                            onChange={() => setFilter(!filter)}
                                            name="filter"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Filtro"}</span>}
                                />
                            </div>
                            <div class="col-2">
                                <FormControlLabel
                                    style={{ fontFamily: 'Gothic' }}
                                    control={
                                        <Switch
                                            checked={isStructure}
                                            onChange={() => setIsStructure(!isStructure)}
                                            name="filter"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Estructura"}</span>}
                                />
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom value={name} setValue={setName} col="col-6" width="95%" placeholder="Ingresa nombre de catálogo" label="Nombre de catálogo" required />
                            <InputFieldCustom value={father} setValue={setFather} col="col-6" width="95%" options={fathers} label="Padre" component="select" />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={structure} setValue={setStructure} col="col-6" width="95%" options={structures} label="Estructura" component="select" />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div class="row">
                            <div class="col-2">
                                <div style={{ marginTop: '0em', marginLeft: '0%' }}>
                                    <FormControlLabel
                                        style={{ fontFamily: 'Gothic' }}
                                        control={
                                            <Switch
                                                checked={filter}
                                                onChange={() => setFilter(!filter)}
                                                name="filter"
                                                color="primary"
                                            />
                                        }
                                        label={<span style={{ fontFamily: 'Gothic', fontWeight: 'bold', fontSize: '0.85em' }}>{"Filtro"}</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <InputFieldCustom col="col-12" width="100%" value={name} setValue={setName} placeholder="Ingresa nombre de catálogo" label="Nombre de catálogo" required name="nombre" type="text" />
                        </div>
                        <div class="row">
                            <InputFieldCustom col="col-12" width="100%" value={father} setValue={setFather} options={fathers} label="Padre" component="select" />
                        </div>
                    </div>)
                }
                <BottomToolBar buttons={[{ auth: true, action: CreateRequest, name: 'Crear', disable: disable }]} />
                <div class="row">
                    <div>
                        <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                            <div>
                                <Alert onClose={handleCloseError} severity="error" className={classes.myAlertError} >
                                    {message}
                                </Alert>
                            </div>
                        </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostCreate