import Button from '@material-ui/core/Button';
import { Style } from './Styles.jsx';

const BottomToolBar = (props) => {
  const { menu, buttons } = props;
  let col = buttons ? buttons.length * 2 : 0;
  col = 11 - col;
  return (
    <div class="row" style={{ marginTop: '1.2em' }}>
      {buttons && buttons.map((button, index) => {
        return button.auth && (
          <div class="col-2" key={index}>
            {button.href ?
              <Button
                style={{
                  ...index % 2 === 0 ? Style.SaveButton : Style.DeleteButton,
                  opacity: button.disable ? 0.5 : 1,
                }}
                href={button.href}
                target='_blank'
                rel='noreferrer'
                disabled={button.disable}>
                {button.name}
              </Button> :
              <Button
                style={{
                  ...index % 2 === 0 ? Style.SaveButton : Style.DeleteButton,
                  opacity: button.disable ? 0.5 : 1,
                }}
                onClick={button.action}
                disabled={button.disable}>
                {button.name}
              </Button>
            }
          </div>
        )
      })}
      <div class={"col-" + col}></div>
      {menu && (
        <div class="col-1">
          {menu}
        </div>
      )}
    </div>
  )
};

export default BottomToolBar;