import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { TextInput, Datagrid, TextField, List } from 'react-admin';
import { useMediaQuery, createTheme, ThemeProvider } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import { Style } from '../Commons/Styles.jsx';
import historyTracker from '../Commons/historyTracker';
import { useLocation } from 'react-router-dom';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useState, useEffect } from "react";

const CatalogList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [historyInfo, setHistoryInfo] = useState(historyTracker.getHistory());
  const location = useLocation();

  const theme = createTheme({
    overrides: {
      MuiCard: {
        root: {
          width: '90%',
        },
      }
    }
  });

  useEffect(() => {
    (async () => {
      try {
        historyTracker.cleanHistory();
        historyTracker.addPath(location.pathname, 'Catálogos', 0);
        setHistoryInfo(historyTracker.getHistory());
      }
      catch {
      }
    })();
  }, []);

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"Nombre"}</span>} source="name" alwaysOn />,
  ]

  return (
    <div class="container">
      <BreadCrumbCustom history={historyInfo} />
      {!isSmall ? (
        <ThemeProvider theme={theme}>
          <List empty={false} {...props} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={{}} />} pagination={<PostPagination />} bulkActionButtons={false} actions={<ListActions create={true} />} style={{ marginLeft: "2%" }}>
            <Datagrid  {...props} rowClick="show">
              <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="nombre" />
            </Datagrid>
          </List>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <List empty={false} {...props} pagination={<PostPagination />} bulkActionButtons={false} actions={<ListActions create={true} />} style={{ marginLeft: "2%" }}>
            <Datagrid  {...props} rowClick="show">
              <TextField style={Style.TextFieldList} label={<span style={Style.dataTitle}>{"Nombre"}</span>} source="nombre" />
            </Datagrid>
          </List>
        </ThemeProvider>
      )}
    </div>);
};

export default CatalogList;